import { useMutation, useQueryClient } from 'react-query';
import { updateFinishTask, updateLockHomework } from '../services';

export const useUpdateHomework = () => {
  const queryClient = useQueryClient();

  const { mutate: finishTask, isLoading: isSubmiting } = useMutation(
    'finish-task',
    updateFinishTask,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('lessons-homework-results');
      },
      onError: (error: any) => {},
    },
  );

  const { mutate: lockedHomework, isLoading: isLockedSubmiting } = useMutation(
    'locked-task',
    updateLockHomework,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('lessons-homework-results');
      },
      onError: (error: any) => {},
    },
  );

  return { finishTask, lockedHomework, isSubmiting, isLockedSubmiting };
};
