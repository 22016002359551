import { useMemo } from 'react';
import {
  IGenericTableColumns,
  IOptions,
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { IInternalTransaction } from '../types';
import moment from 'moment';
import InternalTransactionCategoryStatus from '../components/internal-transaction-category-status';
import { DATA_TYPES } from 'src/shared/costants/constants';
import { useTranslation } from 'react-i18next';
import InternalTransactionsActionButton from '../components/action-button';
import { TransactionsTableAmountCell } from '../components/internal-transactions-table-amount-cell';

export const useInternalTransactionsTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes,
] => {
  const options: IOptions = {
    defaultLimit: 25,
    pageLimits: [5, 25, 50],
  };

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('internalTransactions.title'),
      href: '/dashboard/accounting/internal-transactions',
    },
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'title',
        align: 'left',
        label: t('table.title'),
        extructor: (item: IInternalTransaction) => item.title,
      },
      {
        id: 'category',
        align: 'center',
        label: t('table.category'),
        extructor: (item: IInternalTransaction) => (
          <InternalTransactionCategoryStatus category={item.category} />
        ),
      },
      {
        id: 'amount',
        align: 'center',
        label: t('table.amount'),
        extructor: (item) => (
          <TransactionsTableAmountCell
            type={'income'}
            category={item.category}
            amount={item.amount}
          />
        ),
      },
      {
        id: 'date',
        align: 'center',
        label: t('table.date'),
        extructor: (item: IInternalTransaction) =>
          moment(item.givenDate).format(DATA_TYPES.mdyt),
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item) => {
          return <InternalTransactionsActionButton transactionId={item.id} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
