export const addGroupLessonMapper = ({
  values,
  teacherId,
  groupId,
  program,
}) => {
  return {
    subject: values.subject,
    groupId: groupId,
    heldIn: values.heldIn,
    homeworkPending: values.homeworkAddedLater === 'yes' ? true : false,
    program: program,
    teacherId: teacherId,
    duration: values.duration,
    lessonStarted: values.lessonStarted,
    lessonEnded: values.lessonEnded,
    description: values.description,
    students: values.students.map((student) => student.id),
    homework: values.homeworkAddedLater === 'no' ? values.homework : [],
  };
};
