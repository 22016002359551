import { useTranslation } from 'react-i18next';

import {
  Card,
  Typography,
  Box,
  alpha,
  LinearProgress,
  linearProgressClasses,
  styled,
  useTheme,
  Divider,
} from '@mui/material';

import QuestionsList from './questions-list';
import {
  ILessonHomeworkResultsHomework as ILessonHomeworkResultsHomework,
  ILessonHomeworkResultsLesson,
  ILessonHomeworkResultsStudent,
} from '../types';
import HomeworkList from './homework-list';

const LinearProgressBlack = styled(LinearProgress)<{
  type: 'error' | 'warning' | 'success';
}>(
  ({ theme, type }) => `
            height: 8px;
            border-radius: ${theme.general.borderRadiusLg};
    
            &.${linearProgressClasses.colorPrimary} {
                background-color: ${alpha(theme.colors.alpha.black[100], 0.1)};
            }
            
            & .${linearProgressClasses.bar} { 
              background-color: ${theme.colors[type].main};
            }
        `,
);

export default function StudentProgressCard(props: {
  student: ILessonHomeworkResultsStudent;
  homeworks: ILessonHomeworkResultsHomework[];
  editable: boolean;
  lesson: ILessonHomeworkResultsLesson;
}) {
  const { t } = useTranslation();

  const theme = useTheme();

  const { homeworks, editable, student, lesson } = props;

  const studentHomework: null | ILessonHomeworkResultsHomework =
    homeworks.find((homework) => {
      return homework.studentId === student.id;
    }) || null;

  const ProgressBarColor = (
    percent: number,
  ): 'error' | 'warning' | 'success' => {
    if (100 >= percent && 90 <= percent) {
      return 'success';
    }
    if (90 >= percent && 50 <= percent) {
      return 'warning';
    }
    if (50 >= percent) {
      return 'error';
    }
  };

  return (
    <Card
      sx={{
        p: 2.5,
        flexGrow: 1,
        mb: { xs: '30px', sm: '30px' },
      }}
    >
      <Box sx={{ pb: 2 }}>
        <Box
          mb={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography color="text.primary" variant="h4" gutterBottom>
              {student.fullname}
            </Typography>
          </Box>
        </Box>
        <LinearProgressBlack
          variant="determinate"
          value={studentHomework ? studentHomework.percentage : 0}
          type={ProgressBarColor(
            studentHomework ? studentHomework.percentage : 0,
          )}
        />
        <Box
          display="flex"
          sx={{
            mt: 0.6,
          }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            sx={{
              color: `${theme.colors.alpha.black[50]}`,
            }}
            variant="subtitle2"
          >
            {t('completion.name')}
          </Typography>
          <Typography
            sx={{
              color: `${theme.colors.alpha.black[50]}`,
            }}
            variant="subtitle2"
          >
            {studentHomework ? studentHomework.percentage : 0}%
          </Typography>
        </Box>
      </Box>
      <Divider />
      <HomeworkList
        studentHomework={studentHomework}
        listHomework={lesson.homework}
        editable={editable}
      />
      <Divider sx={{ my: 1 }} />
      <QuestionsList
        listHomeworkTasks={studentHomework?.tasks}
        lessonHomework={lesson.homework}
      />
    </Card>
  );
}
