import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getLessonsHomeworkResults } from '../services';
import { queryError } from 'src/shared/utils/query-error';

export const useLessonsHomeworkResults = () => {
  const { lessonId } = useParams();

  const { data, isLoading } = useQuery(
    ['lessons-homework-results', lessonId],
    getLessonsHomeworkResults,
    {
      select: ({ data }) => {
        if (data?.status === 'success') {
          return {
            group: data.group,
            homeworks: data.homeworks,
            lesson: data.lesson,
          };
        }
      },
      onError: queryError,
    },
  );
  return { data, isLoading };
};
