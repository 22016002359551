import { DateTimePicker } from '@mui/lab';
import { TextField } from '@mui/material';

type TOmDateTimePicker = {
  formik: any;
  label: string;
  name: string;
  ampm?: boolean;
  readOnly?: boolean;
};

export function OmDateTimePicker({
  formik,
  label,
  name,
  ampm,
  readOnly,
}: TOmDateTimePicker) {
  return (
    <DateTimePicker
      value={formik.values[name]}
      onChange={(newDate) => {
        formik.setFieldValue(name, newDate);
      }}
      label={label}
      ampm={ampm}
      readOnly={readOnly}
      renderInput={(params) => (
        <TextField
          {...params}
          {...formik.getFieldProps(name)}
          error={formik.touched[name] && Boolean(formik.errors[name])}
          margin="none"
          variant="outlined"
          fullWidth
          autoComplete="off"
        />
      )}
    />
  );
}
