import { useContext } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import cuid from 'cuid';
import { AlertContext } from 'src/shared/contexts/Alert';
import { queryError } from 'src/shared/utils/query-error';
import { getLesson, updateHomework } from '../services';
import { updateHomeworkMapper } from '../mappers/update-homework-mapper';

export const useLessonDetails = () => {
  const { t } = useTranslation();

  const { lessonId } = useParams();

  const navigate = useNavigate();

  const { handleMessage } = useContext(AlertContext);

  const { data, isLoading } = useQuery(['get-lesson', lessonId], getLesson, {
    select: ({ data }) => {
      if (data?.status === 'success') {
        return data?.lesson;
      }
    },
    onError: queryError,
  });

  const { mutate: updateHomeworkMutate, isLoading: isUpdatingHomework } =
    useMutation('update-homework', updateHomework, {
      onSuccess: (e: any) => {
        if (e.status === 'success') {
          handleMessage(true, 'Process was successful', 'success');
          formik.resetForm();
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    });

  const validationSchemaNewLessonForm = Yup.object().shape({
    homework: Yup.array().when('homeworkAddedLater', {
      is: (value) => value === 'no',
      then: Yup.array()
        .min(1, t('yup.inputs.homework'))
        .required(t('yup.inputs.homework')),
    }),
  });

  const validationHomeworkModalForm = Yup.object().shape({
    title: Yup.string().required(t('yup.inputs.title')),
    percentage: Yup.number()
      .nullable()
      .test(
        'general-percent',
        t('yup.inputs.percentage.total'),
        function (value) {
          const homeworkPercentageTotal = formik.values.homework.reduce(
            (acc, curVal) => acc + curVal.percentage,
            0,
          );
          if (homeworkFormFormik.values.modalType === 'edit') {
            return (
              value +
                homeworkPercentageTotal -
                homeworkFormFormik.values.editPercentage <=
              100
            );
          }
          return value + homeworkPercentageTotal <= 100;
        },
      )
      .required(t('yup.inputs.percentage.required')),
  });

  const formik = useFormik({
    initialValues: {
      subject: data?.subject || '',
      heldIn: data?.heldIn || '',
      lessonStarted: data?.lessonStarted || '',
      lessonEnded: data?.lessonEnded || '',
      duration: data?.duration || 0,
      description: data?.description || '',
      homeworkAddedLater: data?.homeworkPending ? 'yes' : 'no',
      students: data?.students || [],
      homework: data?.homework || [],
    },
    onSubmit: (values) => {
      const homeworkPercentageTotal = formik.values.homework.reduce(
        (acc, curVal) => acc + curVal.percentage,
        0,
      );
      const lessonDetailsBody = updateHomeworkMapper({
        values,
        lessonId,
      });

      if (
        (values.homeworkAddedLater === 'no' && homeworkPercentageTotal < 100) ||
        homeworkPercentageTotal > 100
      ) {
        handleMessage(true, t('modal.pleaseCheckYourFileAgain'), 'error');
        return;
      }

      if (
        values.homeworkAddedLater === 'yes' ||
        homeworkPercentageTotal === 100
      ) {
        updateHomeworkMutate(lessonDetailsBody);
      }
    },

    validationSchema: validationSchemaNewLessonForm,
    enableReinitialize: true,
  });

  const homeworkFormFormik = useFormik({
    initialValues: {
      id: '',
      title: '',
      percentage: '',
      editPercentage: '',
      link: '',
      homeworkFormDescription: '',
      homeworkFormIsOpen: false,
      modalType: 'add',
      calculatePercentage: 0,
    },
    onSubmit: (values) => {
      if (values.title.trim() && values.percentage !== '') {
        const foundHomeworkIndex = formik.values.homework.findIndex(
          (item) => item._id === values.id,
        );

        const updatedHomework = {
          _id: cuid(),
          title: values.title,
          link: values.link,
          percentage: values.percentage,
        };

        if (foundHomeworkIndex === -1) {
          formik.setFieldValue('homework', [
            ...formik.values.homework,
            updatedHomework,
          ]);
        } else {
          const updatedHomeworkList = [...formik.values.homework];

          updatedHomeworkList[foundHomeworkIndex] = {
            _id: values.id,
            title: values.title,
            link: values.link,
            percentage: values.percentage,
          };

          formik.setFieldValue('homework', updatedHomeworkList);
        }

        homeworkFormFormik.setFieldValue('homeworkFormIsOpen', false);
        homeworkFormFormik.resetForm();
      }
    },

    validationSchema: validationHomeworkModalForm,
    enableReinitialize: true,
  });

  return {
    data,
    formik,
    homeworkFormFormik,
    isLoading,
    isUpdatingHomework,
  };
};
