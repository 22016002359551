import { Box, Button, Card, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/system';
import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GenericTable from 'src/shared/components/generic-table';
import PageWrapper from 'src/shared/components/page-wrapper';
import Statistics from '../components/statistics';
import StatisticsProvider from '../providers/statistics-provider';
import { useTranslation } from 'react-i18next';
import { useStudentsTable } from 'src/modules/students/hooks/use-students-table';
import { useAddLesson } from '../hooks/use-add-lesson';
import { useLessonsTable } from 'src/modules/lessons/hooks/use-lessons-table';

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `,
);

const GroupDetailsContainer = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useState('lessons');

  const { data } = useAddLesson();

  const navigate = useNavigate();

  const [studentTableColumns, studentTableOptions] = useStudentsTable();

  const [lessonsTableColumns, lessonsTableOptions] = useLessonsTable();

  const tabs = [
    {
      value: 'lessons',
      label: t('tabs.lessons'),
    },
    {
      value: 'students',
      label: t('tabs.students'),
    },
    // {
    //   value: 'resources',
    //   label: t('tabs.resources'),
    // },
    {
      value: 'statistics',
      label: t('tabs.statistics'),
    },
  ];

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('groups.title'),
      href: '/dashboard/groups',
    },
    {
      label: t('groups.groupDetails.title'),
      href: '/dashboard/groups',
    },
  ];

  return (
    <>
      <PageWrapper
        isPaper={false}
        title={t('groups.groupDetails.title')}
        action={
          <Button
            onClick={() => {
              navigate(`/dashboard/groups/${data.groupId}/add-lesson`);
            }}
            variant="contained"
          >
            {t('groups.groupDetails.addLesson')}
          </Button>
        }
        routes={routes}
      >
        <Card sx={{ p: 2 }}>
          <TabsWrapper
            onChange={(_event: SyntheticEvent, tabValue: string) => {
              setTab(tabValue);
            }}
            scrollButtons="auto"
            textColor="secondary"
            value={tab}
            variant="scrollable"
          >
            {tabs.map((tab) => (
              <Tab
                sx={{ textTransform: 'lowercase' }}
                key={tab.value}
                value={tab.value}
                label={tab.label}
              />
            ))}
          </TabsWrapper>
        </Card>
        {tab === 'lessons' && (
          <Card sx={{ mt: 2 }}>
            <GenericTable
              data={data?.lessons}
              columns={lessonsTableColumns}
              options={lessonsTableOptions}
            />
          </Card>
        )}
        {tab === 'students' && (
          <Card sx={{ mt: 2 }}>
            <GenericTable
              data={data?.students}
              columns={studentTableColumns}
              options={studentTableOptions}
            />
          </Card>
        )}
        {tab === 'statistics' && (
          <StatisticsProvider>
            <Statistics />
          </StatisticsProvider>
        )}
      </PageWrapper>
    </>
  );
};

export default GroupDetailsContainer;
