import { Link as RouterLink } from 'react-router-dom';

import { Box, Avatar, Tooltip, AvatarGroup } from '@mui/material';
import { IUserAvatarTiny } from 'src/modules/users/types/intex';

export default function UsersAvatarsList({
  users
}: {
  users: IUserAvatarTiny[];
}) {
  return (
    <Box
      sx={{
        pt: { xs: 2, sm: 0 }
      }}
      display="flex"
      alignItems="center"
    >
      <AvatarGroup
        max={4}
        sx={{
          mr: 2
        }}
      >
        {users.map((user, index: number) => {
          return (
            <Tooltip arrow title={user?.name ?? ''} key={index}>
              <Avatar
                sx={{
                  width: 27,
                  height: 27
                }}
                component={RouterLink}
                to="#"
                alt="student"
                src={user.profileImage}
              />
            </Tooltip>
          );
        })}
      </AvatarGroup>
    </Box>
  );
}
