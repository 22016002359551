import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGenericTableColumns,
  IOptions,
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { ILesson } from '../types';
import { LessonsTableActionButtons } from '../components/action-buttons';

export const useLessonsTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes,
] => {
  const { t } = useTranslation();

  const options: IOptions = {
    defaultLimit: 25,
  };

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('pages.lessons'),
      href: '/dashboard/lessons',
    },
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'subject',
        align: 'left',
        label: t('table.subject'),
        extructor: (item: ILesson) => item.subject,
      },
      {
        id: 'code',
        align: 'center',
        label: t('table.code'),
        extructor: (item: ILesson) => item.code,
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item: ILesson) => {
          return <LessonsTableActionButtons lesson={item} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
