import { Groups } from '../pages/groups';
import GroupDetails from '../pages/group-details';
import LessonHomeworkResults from '../pages/lesson-homework-results';
import AddLesson from '../pages/add-lesson';
import { LessonDetails } from '../pages/lesson-details';

const groupsRouter = {
  path: 'groups',
  children: [
    {
      path: '',
      element: <Groups />,
    },
    {
      path: ':groupId',
      element: <GroupDetails />,
    },
    {
      path: ':groupId/add-lesson',
      element: <AddLesson />,
    },
    {
      path: ':groupId/results/:lessonId',
      element: <LessonHomeworkResults />,
    },
    {
      path: ':groupId/lesson-details/:lessonId',
      element: <LessonDetails />,
    },
  ],
};

export default groupsRouter;
