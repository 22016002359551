import { useState } from 'react';
import { TransactionsTableContext } from '../contexts/transactions-table-context';

function TransactionsTableContextProvider({ children }) {
  const [page, setPage] = useState('0');
  const [limit, setLimit] = useState('5');

  return (
    <TransactionsTableContext.Provider
      value={{ page, limit, setPage, setLimit }}
    >
      {children}
    </TransactionsTableContext.Provider>
  );
}

export default TransactionsTableContextProvider;
