import { useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { getStats } from '../services';

export const useStats = () => {
  const { data, isLoading } = useQuery({
    queryKey: 'get-stats',
    queryFn: getStats,
    select: ({ data }) => {
      if (data?.status === 'success') {
        return data;
      }
    },
    onError: queryError,
  });

  return { data, isLoading };
};
