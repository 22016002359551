import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { queryError } from 'src/shared/utils/query-error';
import { getInternalTransactionbyId } from '../services';

export const useInternalTransactionDetails = (): [
  any,
  IBreadcrumbRoutes,
  boolean,
] => {
  const { t } = useTranslation();
  const { internalTransactionId } = useParams();

  const { data, isLoading: isSubmiting } = useQuery(
    ['internal-transaction', internalTransactionId],
    () => getInternalTransactionbyId(internalTransactionId),
    {
      select: ({ data }) => {
        if (data?.status === 'success') {
          return data.data;
        }
      },
      onError: queryError,
    },
  );

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('internalTransactions.title'),
      href: '/dashboard/accounting/internal-transactions',
    },
    {
      label: `${data?.code}`,
      href: `/dashboard/accounting/${data?.code}`,
    },
  ];

  return [data, routes, isSubmiting];
};
