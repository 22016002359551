import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cuid from 'cuid';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useLessonDetails } from '../hooks/use-lesson-details';
import { LessonDetailsForm } from '../components/lesson-details-form';
import SubmitButton from 'src/shared/components/Submit-button';
import readXlsxFile from 'read-excel-file';
import { AlertContext } from 'src/shared/contexts/Alert';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import GroupHomework from '../components/group-homework';
import styled from '@emotion/styled';
import GroupDialog from '../components/group-dialog';
import { HomeworkForm } from '../components/homework-form';

const Input = styled('input')({
  display: 'none',
});

export const LessonDetailsContainer = () => {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  const { handleMessage } = useContext(AlertContext);

  const { data, formik, homeworkFormFormik, isLoading, isUpdatingHomework } =
    useLessonDetails();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('groups.title'),
      href: '/dashboard/groups',
    },
    {
      label: data?.code ? data?.code : '',
      href: `/dashboard/lessons/${data?.id}`,
    },
  ];

  function updateAllHomeworks(e) {
    const file = e.target.files[0];

    if (file) {
      e.target.value = null;
    }

    readXlsxFile(file).then((rows) => {
      const homework = rows.map((item) => {
        return {
          id: cuid(),
          title: item[0],
          description: item[1] ? item[1] : '',
          link: item[2] ? item[2] : '',
          percentage: item[3],
        };
      });
      const sumOfHomeworkPercentages = homework.reduce(
        (acc, item) => acc + Number(item.percentage),
        0,
      );
      if (sumOfHomeworkPercentages !== 100) {
        return handleMessage(
          true,
          t('modal.pleaseCheckYourFileAgain'),
          'error',
        );
      }
      formik.setFieldValue('homework', homework);
    });
  }

  const submit = () => {
    if (Object.keys(formik.errors).length > 0) {
      return setOpenDialog(true);
    }
  };

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={data?.code ? data?.code : ''}
    >
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <Box component="form" onSubmit={formik.handleSubmit} sx={{ mb: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h3" sx={{ mb: 1 }}>
                {t('pages.addLesson.lessonDetails.title')}
              </Typography>
              <Card sx={{ p: 2 }}>
                <LessonDetailsForm formik={formik} />
              </Card>
            </Grid>
            {formik.values.homeworkAddedLater === 'no' && (
              <Grid item xs={12} sx={{ py: 2, mt: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography variant="h3" sx={{ mb: 1 }}>
                    {t('homework.title')}
                  </Typography>
                  <Box>
                    <label htmlFor="contained-button-file">
                      <Input
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => {
                          updateAllHomeworks(e);
                        }}
                      />
                      <Button
                        sx={{ mr: 1 }}
                        variant="contained"
                        startIcon={<UploadFileIcon />}
                        component="span"
                      >
                        {t('buttons.importExcelFile')}
                      </Button>
                    </label>
                  </Box>
                </Box>
                <HomeworkForm
                  formik={formik}
                  homeworkFormFormik={homeworkFormFormik}
                />
                <GroupHomework
                  formik={formik}
                  homeworkFormFormik={homeworkFormFormik}
                />
              </Grid>
            )}
            <Grid item xs={12} container justifyContent="end">
              <SubmitButton
                load={isUpdatingHomework}
                sx={{ width: { xs: '100%', md: 300 } }}
                callback={submit}
              >
                {t('buttons.confirm')}
              </SubmitButton>
            </Grid>
            <GroupDialog
              formik={formik}
              open={openDialog}
              closeDialog={() => setOpenDialog(false)}
              type="error"
              confirm={submit}
            />
          </Grid>
        </Box>
      )}
    </PageWrapper>
  );
};
