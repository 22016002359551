import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const SubmitButton = ({
  load,
  children,
  sx,
  callback
}: {
  load: Boolean;
  children: string;
  sx?: any;
  callback?: () => any;
}) => {
  return (
    <>
      {load ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems={'center'}
          sx={{ height: '40px', width: '200px' }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Button
          sx={{
            px: 4,
            ...sx
          }}
          variant="contained"
          type="submit"
          onClick={() => {
            callback?.();
          }}
        >
          {children}
        </Button>
      )}
    </>
  );
};

export default SubmitButton;
