import { useMemo } from 'react';
import {
  IGenericTableColumns,
  IOptions,
} from 'src/shared/components/generic-table/types';
import { IBreadcrumbRoutes } from 'src/shared/types';
import { IGroup } from '../types';
import GroupsTableActionButtons from '../components/action-buttons';
import UsersAvatarsList from 'src/shared/components/users-avatars';
import { IUserAvatarTiny } from 'src/modules/users/types/intex';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

export const useGroupsTable = (): [
  IGenericTableColumns,
  IOptions,
  IBreadcrumbRoutes,
] => {
  const options: IOptions = {
    defaultLimit: 25,
  };

  const { t } = useTranslation();

  const routes = [
    {
      label: t('pages.home'),
      href: '/dashboard',
    },
    {
      label: t('groups.title'),
      href: '/dashboard/groups',
    },
  ];

  const columns = useMemo(() => {
    const columns: IGenericTableColumns = [
      {
        id: 'code',
        align: 'left',
        label: t('table.code'),
        extructor: (item: IGroup) => item.code,
      },
      {
        id: 'status',
        align: 'center',
        label: t('table.status'),
        extructor: (item: IGroup) => item.status,
      },
      {
        id: 'room',
        align: 'center',
        label: t('table.room'),
        extructor: (item: IGroup) => item.room,
      },
      {
        id: 'students',
        align: 'center',
        label: t('table.students'),
        extructor: (item: IGroup) => item.students,
        render: (students: IUserAvatarTiny[]) => (
          <Box display="flex" justifyContent="center">
            <UsersAvatarsList users={students} />
          </Box>
        ),
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item: IGroup) => {
          return <GroupsTableActionButtons group={item} />;
        },
      },
    ];
    return columns;
  }, [t]);

  return [columns, options, routes];
};
