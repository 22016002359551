import { TransactionsContainer } from '../containers/transactions';
import TransactionsTableContextProvider from '../provider/transactions-table-context-provider';

export function Transactions() {
  return (
    <TransactionsTableContextProvider>
      <TransactionsContainer />;
    </TransactionsTableContextProvider>
  );
}
