import { AxiosResponse } from 'axios';

import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';

export const getStudents = async (query): Promise<AxiosResponse> => {
  const [, page, limit, title] = query.queryKey;

  const queryObj: any = {
    ...(page && { page: Number(page) + 1 }),
    ...(limit && { limit: limit }),
    ...(title && { query: title }),
  };

  const queryString = '?' + new URLSearchParams(queryObj).toString();

  try {
    return await axios.get(`/api/v2/teacher-api/students${queryString}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getStudentbyId = async (
  studentId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v2/teacher-api/students/${studentId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const updateStudent = async (requestObj: any) => {
  try {
    const { data: newData } = await axios.patch(
      `/api/v2/teacher-api/students/${requestObj.studentId}`,
      requestObj,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
    return newData;
  } catch (e) {
    return e;
  }
};
