import { useQuery } from 'react-query';
import { getGroups } from '../services';
import { IGroup } from '../types';
import { groupGetAllDataMapper } from '../mappers/group-get-all-data-mapper';
import { queryError } from 'src/shared/utils/query-error';

export const useGroups = () => {
  const { data } = useQuery('groups-get-all', getGroups, {
    select: ({ data }) => {
      if (data?.status === 'success') {
        const groups: IGroup[] = groupGetAllDataMapper(data);
        return groups;
      }
    },
    onError: queryError,
  });

  return {
    data,
  };
};
