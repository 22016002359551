import { useContext, useEffect, useState } from 'react';
import readXlsxFile from 'read-excel-file';
import { useTranslation } from 'react-i18next';
import cuid from 'cuid';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import GroupStudent from 'src/modules/groups/components/group-student';
import GroupHomework from 'src/modules/groups/components/group-homework';
import { LessonForm } from './lesson-form';
import { Grid, Box, Typography, Card, Button, styled } from '@mui/material';
import { useAddLesson } from '../hooks/use-add-lesson';
import SubmitButton from 'src/shared/components/Submit-button';
import GroupDialog from './group-dialog';
import { AlertContext } from 'src/shared/contexts/Alert';
import { HomeworkForm } from './homework-form';

const Input = styled('input')({
  display: 'none',
});

const AddLessonForm = () => {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  const { handleMessage } = useContext(AlertContext);

  const { formik, data, homeworkFormFormik, isCreatingLessons } =
    useAddLesson();

  function updateAllHomeworks(e) {
    const file = e.target.files[0];

    if (file) {
      e.target.value = null;
    }

    readXlsxFile(file).then((rows) => {
      const homework = rows.map((item) => {
        return {
          id: cuid(),
          title: item[0],
          description: item[1] ? item[1] : '',
          link: item[2] ? item[2] : '',
          percentage: item[3],
        };
      });
      const sumOfHomeworkPercentages = homework.reduce(
        (acc, item) => acc + Number(item.percentage),
        0,
      );
      if (sumOfHomeworkPercentages !== 100) {
        return handleMessage(
          true,
          t('modal.pleaseCheckYourFileAgain'),
          'error',
        );
      }
      formik.setFieldValue('homework', homework);
    });
  }

  const submit = () => {
    if (Object.keys(formik.errors).length > 0) {
      return setOpenDialog(true);
    }
  };

  useEffect(() => {
    homeworkFormFormik.setFieldValue('modalType', 'add');
  }, []);

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mb: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {t('pages.addLesson.lessonDetails.title')}
          </Typography>
          <Card sx={{ p: 2 }}>
            <LessonForm formik={formik} data={data} />
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ py: 2 }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {t('students.title')}
          </Typography>
          <GroupStudent formik={formik} />
        </Grid>
        {formik.values.homeworkAddedLater === 'no' && (
          <Grid item xs={12} sx={{ py: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Typography variant="h3" sx={{ mb: 1 }}>
                {t('homework.title')}
              </Typography>
              <Box>
                <label htmlFor="contained-button-file">
                  <Input
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => {
                      updateAllHomeworks(e);
                    }}
                  />
                  <Button
                    sx={{ mr: 1 }}
                    variant="contained"
                    startIcon={<UploadFileIcon />}
                    component="span"
                  >
                    {t('buttons.importExcelFile')}
                  </Button>
                </label>
              </Box>
            </Box>
            <HomeworkForm
              formik={formik}
              homeworkFormFormik={homeworkFormFormik}
            />
            <GroupHomework
              formik={formik}
              homeworkFormFormik={homeworkFormFormik}
            />
          </Grid>
        )}
        <Grid item xs={12} container justifyContent="end">
          <SubmitButton
            load={isCreatingLessons}
            sx={{ width: { xs: '100%', md: 300 } }}
            callback={submit}
          >
            {t('buttons.confirm')}
          </SubmitButton>
        </Grid>
        <GroupDialog
          formik={formik}
          open={openDialog}
          closeDialog={() => setOpenDialog(false)}
          type="error"
          confirm={submit}
        />
      </Grid>
    </Box>
  );
};

export default AddLessonForm;
