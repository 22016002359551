import { useState } from 'react';
import { StudentsTableContext } from '../contexts/students-table-context';

function StudentsTableContextProvider({ children }) {
  const [page, setPage] = useState('0');
  const [limit, setLimit] = useState('5');

  return (
    <StudentsTableContext.Provider value={{ page, limit, setPage, setLimit }}>
      {children}
    </StudentsTableContext.Provider>
  );
}

export default StudentsTableContextProvider;
