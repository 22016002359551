import {
  Box,
  Typography,
  Card,
  Grid,
  Divider,
  Container,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  styled,
} from '@mui/material';

import Logo from 'src/components/LogoSign';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useInternalTransactionDetails } from '../hooks/use-internal-transactions-details';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';

const TableWrapper = styled(Box)(
  ({ theme }) => `
  border: 1px solid ${theme.colors.alpha.black[10]};
  border-bottom: 0;
  margin: ${theme.spacing(4)} 0;
`,
);

const LogoWrapper = styled(Box)(
  () => `
    width: '52px'
`,
);

const BoxWrapper = styled(Box)(
  ({ theme }) => `
  border-radius: ${theme.general.borderRadius};
  background: ${theme.colors.alpha.black[5]};
`,
);

export const InternalTransactionDetails = () => {
  const [data, routes, isSubmiting] = useInternalTransactionDetails();

  const { t } = useTranslation();

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('internalTransactionDetails.title')}
    >
      <Box>
        {isSubmiting ? (
          <Card
            sx={{
              height: '550px',
              p: 3,
              mb: 3,
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={'100%'}
            >
              <CircularProgress size={'50px'} />
            </Box>
          </Card>
        ) : (
          <Card
            sx={{
              p: 3,
              mb: 3,
            }}
          >
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h1" gutterBottom>
                  {t('accounting.invoiceDetails.title')}
                </Typography>
                <Typography variant="h3" color="text.secondary">
                  # {data?.code}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <LogoWrapper>
                  <Logo />
                </LogoWrapper>
                <Typography
                  sx={{
                    py: 2,
                  }}
                  variant="h4"
                >
                  Suqra Memmedova
                </Typography>
              </Box>
            </Box>
            <Divider
              sx={{
                my: 4,
              }}
            />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  spacing={4}
                  justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                >
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {t('accounting.invoiceDetails.issuedBy')}:
                    </Typography>
                    <Typography
                      sx={{
                        pb: 2,
                      }}
                      variant="h5"
                    >
                      {data?.createByApp ? 'app' : data?.createdBy?.fullname}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {t('accounting.invoiceDetails.issuedOn')}:
                    </Typography>
                    <Typography
                      sx={{
                        pb: 2,
                      }}
                      variant="h5"
                    >
                      {moment(data?.createdAt).format('L')}
                    </Typography>
                  </Grid>
                </Grid>
                <BoxWrapper textAlign="right" mt={1} p={3}>
                  <Typography component="span" variant="h4" fontWeight="normal">
                    {t('amount')}:{' '}
                  </Typography>
                  <Typography component="span" variant="h4">
                    {data?.amount} &#8380;
                  </Typography>
                </BoxWrapper>
              </Grid>
            </Grid>
            <TableWrapper>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography noWrap>
                          {t(
                            'accounting.invoiceDetails.teacherBalanceBeforePayment',
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {data?.teacherBalanceBeforePayment} &#8380;
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography noWrap>
                          {t(
                            'accounting.invoiceDetails.teacherBalanceAfterPayment',
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {data?.teacherBalanceAfterPayment} &#8380;
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </TableWrapper>
            {/* <Typography variant="subtitle2" gutterBottom>
          {t('Additional informations')}
        </Typography>
        <Typography variant="body2">
          These projects were completed between January and February of 2021.
        </Typography>
        <Tooltip
          placement="top"
          arrow
          title="This functionality will be added in a future release!"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={4}
          >
            <Button
              disabled
              variant="contained"
              sx={{
                mx: 2
              }}
              startIcon={<DownloadTwoToneIcon />}
            >
              {t('Download PDF')}
            </Button>
            <Button
              disabled
              variant="outlined"
              sx={{
                mx: 2
              }}
              startIcon={<PictureAsPdfTwoToneIcon />}
            >
              {t('Preview PDF')}
            </Button>
          </Box>    
        </Tooltip> */}
          </Card>
        )}
      </Box>
    </PageWrapper>
  );
};
