import { useTranslation } from 'react-i18next';
import { Box, Grid, FormControlLabel } from '@mui/material';
import Radio from '@mui/material/Radio';
import { OmInput } from 'src/shared/components/form-components/om-input';
import { OmDateTimePicker } from 'src/shared/components/form-components/om-date-time-picker';

export const LessonDetailsForm = ({ formik }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} md={6}>
          <OmInput
            formik={formik}
            label={t('subject')}
            name="subject"
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <OmInput
            formik={formik}
            label={t('room')}
            name="heldIn"
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OmDateTimePicker
            formik={formik}
            label={t('lessonStarting')}
            name="lessonStarted"
            ampm={false}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OmDateTimePicker
            formik={formik}
            label={t('lessonEnding')}
            name="lessonEnded"
            ampm={false}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <OmInput
            formik={formik}
            label={t('duration')}
            name="duration"
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Box>
            <b>{t('pages.addLesson.lessonDetails.homeworkWillBeAddedLater')}</b>
          </Box>
          <FormControlLabel
            value="yes"
            control={
              <Radio
                checked={formik.values.homeworkAddedLater === 'yes'}
                onChange={formik.handleChange}
                value="yes"
                color="primary"
                name="homeworkAddedLater"
              />
            }
            label={t('yes')}
          />
          <FormControlLabel
            value="no"
            control={
              <Radio
                checked={formik.values.homeworkAddedLater === 'no'}
                onChange={formik.handleChange}
                value="no"
                color="primary"
                name="homeworkAddedLater"
              />
            }
            label={t('no')}
          />
        </Grid>
        <Grid item xs={12}>
          <OmInput
            formik={formik}
            label={t('description')}
            name="description"
            multiline={true}
            rows={3}
            readOnly={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
