import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Tooltip } from '@mui/material';
import PageWrapper from 'src/shared/components/page-wrapper';
import LessonHomeworkResultsList from '../containers/lesson-homework-results-list';
import { useGroupsTable } from '../hooks/use-groups-table';
import { useLessonsHomeworkResults } from '../hooks/use-lessons-homework-results';
import LockIcon from '@mui/icons-material/Lock';
import { useLockedAllHomeworkForStudent } from '../hooks/use-locked-all-homework-for-student';

export default function LessonHomeworkResults() {
  const { t } = useTranslation();

  const [, , routes] = useGroupsTable();

  const { lockedAllHomeworkForStudentMutate, isUpdatingHomework } =
    useLockedAllHomeworkForStudent();

  const { data, isLoading } = useLessonsHomeworkResults();

  return (
    <>
      <Helmet>
        <title>{t('groups.title')}</title>
      </Helmet>
      <PageWrapper
        isPaper={false}
        routes={routes}
        title={t('pages.lessons')}
        action={
          <Box
            sx={{
              top: { md: '70px', sm: '70px', xs: '62px' },
              right: { md: '27px', sm: '27px', xs: '8px' },
            }}
          >
            <Tooltip
              title={t('tooltip.lockedAllHomeworkForStudent')}
              placement="top"
            >
              <Button
                variant="outlined"
                size="small"
                sx={{
                  fontSize: { xs: '11px', sm: '13px', md: '13px' },
                  px: { xs: '10px', sm: '12px', md: '12px' },
                  py: { xs: '3px', sm: '6px', md: '6px' },
                }}
                onClick={() => {
                  lockedAllHomeworkForStudentMutate({});
                }}
                disabled={isUpdatingHomework}
              >
                <LockIcon fontSize="medium" />
              </Button>
            </Tooltip>
          </Box>
        }
      >
        <Grid
          sx={{
            my: 2,
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          <LessonHomeworkResultsList data={data} isLoading={isLoading} />
        </Grid>
      </PageWrapper>
    </>
  );
}
