import { useTranslation } from 'react-i18next';
import { Card, Typography } from '@mui/material';
import GenericTable from 'src/shared/components/generic-table';
import { useGroupStudentTable } from '../hooks/use-group-student-table';

const GroupStudent = ({ formik }) => {
  const { t } = useTranslation();

  const studentsData = formik.values.students;

  const [columns] = useGroupStudentTable(formik);

  return (
    <>
      {studentsData?.length === 0 ? (
        <Card
          sx={{
            minHeight: '200px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" fontWeight={'normal'} color="text.secondary">
            {t('message.studentNotFound')}
          </Typography>
        </Card>
      ) : (
        <Card>
          <GenericTable data={studentsData} columns={columns} />
        </Card>
      )}
    </>
  );
};

export default GroupStudent;
