import { Card, CircularProgress, Grid } from '@mui/material';

import StudentProgressCard from '../components/student-progress-card';

function LessonHomeworkResultsList({ data, isLoading }) {
  const students = data?.group.students;

  return (
    <Grid container item spacing={4}>
      {isLoading ? (
        <Grid item xs={12} md={4} sx={{ pt: '0 !important' }}>
          <Card
            sx={{
              p: 2.5,
              flexGrow: 1,
              height: '420px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Card>
        </Grid>
      ) : (
        students?.map((student) => (
          <Grid
            key={student.id}
            item
            xs={12}
            md={6}
            lg={5}
            sx={{ pt: '0 !important' }}
          >
            <StudentProgressCard
              student={student}
              homeworks={data?.homeworks}
              editable={false}
              lesson={data?.lesson}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
}

export default LessonHomeworkResultsList;
