import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch
} from '@mui/material';
import { UIContext } from 'src/shared/contexts/uiContext';
import { useTranslation } from 'react-i18next';

interface ILessonMode {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LessonMode = ({ setOpen }: ILessonMode) => {
  const { lessonMode, setLessonMode } = React.useContext(UIContext);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = () => {
    setLessonMode(!lessonMode);

    if (!lessonMode && location.pathname.includes('accounting')) {
      navigate('/dashboard/groups');
    }
  };

  return (
    <FormControl
      component="fieldset"
      variant="standard"
      sx={{ marginBottom: '5px' }}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={lessonMode}
              onChange={handleChange}
              name="gilad"
              color="secondary"
              onClick={() => setOpen(false)}
            />
          }
          labelPlacement="start"
          label={t("popover.lessonMode")}
        />
      </FormGroup>
    </FormControl>
  );
};

export default LessonMode;
