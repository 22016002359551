import { useState } from 'react';

import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  styled,
} from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';

import LoadingCircle from 'src/shared/components/Loading-circle';
import { HomeworkIcons } from './homework-icons';
import { useUpdateHomework } from '../hooks/use-update-homework';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const CheckboxWrapper = styled(Checkbox)(
  ({ theme }) => `
      padding: ${theme.spacing(0.5)};
  `,
);

export default function HomeworkList({
  listHomework,
  studentHomework,
}: {
  listHomework: any[];
  editable: boolean;
  studentHomework: any;
}) {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selected, setSelected] = useState('');

  const { finishTask, isSubmiting } = useUpdateHomework();

  function findTask(taskId) {
    const homework = studentHomework?.tasks.find(({ task }) => {
      return taskId === task;
    });

    return homework;
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1,
        }}
      >
        <Typography sx={{ py: 1, fontSize: 16 }} variant="body1">
          {t('homework.title')}
        </Typography>
        {studentHomework && (
          <HomeworkIcons
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            isLocked={studentHomework?.isLocked}
            studentHomework={studentHomework}
          />
        )}
      </Box>
      <Box>
        <FormGroup>
          {listHomework &&
            listHomework.map(({ title, _id }: any, index) => {
              const task = findTask(_id);
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center ',
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      control={
                        _id === selected && isSubmiting ? (
                          <CircularProgress size={24} sx={{ m: 0.5 }} />
                        ) : (
                          <CheckboxWrapper
                            color="secondary"
                            name="checkedC"
                            checked={task?.isFinished}
                            disabled={!isEdit || isSubmiting}
                          />
                        )
                      }
                      label={title}
                      onClick={(e) => {
                        if (!isEdit) return;
                        setSelected(_id);
                        finishTask({
                          finishedTaskId: _id,
                          homeworkId: studentHomework._id,
                        });
                      }}
                    />
                  </Box>
                  <Typography
                    sx={(theme) => ({
                      fontSize: 12,
                      color: theme.colors.secondary.light,
                    })}
                    variant="body1"
                  >
                    {task?.isFinished
                      ? moment(task?.finished).format('MMMM DD, h:mm a')
                      : 'incomplete'}
                  </Typography>
                </Box>
              );
            })}
          {!listHomework && <LoadingCircle />}
        </FormGroup>
      </Box>
    </Box>
  );
}
