import {
  Box,
  styled,
  IconButton,
  lighten,
  Tooltip,
  FormControlLabel,
  Switch,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useUpdateHomework } from '../hooks/use-update-homework';
import { useTranslation } from 'react-i18next';

const IconButtonDone = styled(IconButton)(
  ({ theme }) => `
       background: ${theme.colors.success.lighter};
       color: ${theme.colors.success.main};
       padding: ${theme.spacing(0.5)};
  
       &:hover {
        background: ${lighten(theme.colors.success.lighter, 0.4)};
       }
  `,
);

export const HomeworkIcons = ({
  isEdit,
  setIsEdit,
  isLocked,
  studentHomework,
}) => {
  const { t } = useTranslation();

  const { lockedHomework, isLockedSubmiting } = useUpdateHomework();

  const handleChecked = () => {
    setIsEdit(!isEdit);
  };

  const handleLock = () => {
    lockedHomework({ homeworkId: studentHomework?.id });
  };

  return (
    <Box display={'flex'} alignItems={'center'} gap={1}>
      <Tooltip
        title={
          isLocked
            ? t('tooltip.lockedHomeworkForStudent')
            : t('tooltip.unlockHomeworkForStudent')
        }
        placement="top"
      >
        <Box display={'flex'} alignItems={'center'} gap={0.5}>
          <LockOpenIcon
            fontSize="small"
            sx={{ opacity: isLocked && '0.6', marginRight: '3px' }}
          />
          <FormControlLabel
            sx={{ margin: '-10px' }}
            control={
              <Switch
                checked={isLocked}
                disabled={isLockedSubmiting}
                onChange={handleLock}
              />
            }
            label=""
          />
          <LockIcon fontSize="small" sx={{ opacity: !isLocked && '0.6' }} />
        </Box>
      </Tooltip>
      <Tooltip
        title={`${
          isEdit ? t('tooltip.finishEditing') : t('tooltip.startEditing')
        }`}
        placement="top"
      >
        <IconButtonDone onClick={handleChecked}>
          {isEdit ? (
            <DoneIcon fontSize="small" />
          ) : (
            <EditIcon fontSize="small" />
          )}
        </IconButtonDone>
      </Tooltip>
    </Box>
  );
};
