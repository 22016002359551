import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { OmInput } from 'src/shared/components/form-components/om-input';

export const HomeworkForm = ({ formik, homeworkFormFormik }) => {
  const { t } = useTranslation();

  const handleHomeworkForm = () => {
    homeworkFormFormik.setFieldValue(
      'homeworkFormIsOpen',
      !homeworkFormFormik.values.homeworkFormIsOpen,
    );
  };

  const total = formik.values.homework.reduce(
    (acc, curVal) => acc + curVal.percentage,
    0,
  );

  return (
    <Card
      sx={{
        p: 2,
        mb: 3,
        borderRadius: '4px',
      }}
    >
      <Box
        onClick={handleHomeworkForm}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '100%', cursor: 'pointer' }}
      >
        <Typography>{t('homework.homeworkForm')}</Typography>

        {!homeworkFormFormik.values.homeworkFormIsOpen && (
          <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} />
        )}
        {homeworkFormFormik.values.homeworkFormIsOpen && (
          <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} />
        )}
      </Box>
      {homeworkFormFormik.values.homeworkFormIsOpen && (
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2} direction="row" sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6} md={4}>
              <OmInput
                formik={homeworkFormFormik}
                label={t('title')}
                name="title"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OmInput
                formik={homeworkFormFormik}
                label={t('percentage')}
                name="percentage"
                type="number"
                onWheel={(e: any) => e.target.blur()}
                endAdornment={
                  <InputAdornment position="end" sx={{ opacity: 0.8 }}>
                    {homeworkFormFormik.values.percentage
                      ? 100 -
                        total +
                        homeworkFormFormik.values.calculatePercentage
                      : 100 - total}
                    %
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <OmInput
                formik={homeworkFormFormik}
                label={t('link')}
                name="link"
              />
            </Grid>
            <Grid item xs={12}>
              <OmInput
                formik={homeworkFormFormik}
                label={t('description')}
                name="homeworkFormDescription"
                multiline={true}
                rows={3}
              />
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
              <Button
                variant="contained"
                type="submit"
                onClick={homeworkFormFormik.handleSubmit}
              >
                {t('buttons.confirm')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Card>
  );
};
