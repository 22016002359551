import { IDateTypes, IRegularExpressions } from '../types';

export const DATA_TYPES: IDateTypes = {
  mdy: 'YYYY-MM-DD',
  ll: 'll',
  mdyt: 'YYYY-MM-DD HH:mm'
};

export const REGULAR_EXPRESSIONS: IRegularExpressions = {
  phone: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g, // eslint-disable-line
  nameAndEmail:
    /^[AaBbCcÇçDdEeƏəFfGgĞğHhXxIıİiJjKkQqLlMmNnOoÖöPpRrSsŞşTtUuÜüVvYyZz]+$/,
  decimalNumber: /^\d+\.?\d*$/,
  integer: /^\d+$/
};
