import { useContext } from 'react';
import { useMutation } from 'react-query';
import { lockedAllHomeworkForStudent } from '../services';
import { AlertContext } from 'src/shared/contexts/Alert';

export const useLockedAllHomeworkForStudent = () => {
  const { handleMessage } = useContext(AlertContext);

  const {
    mutate: lockedAllHomeworkForStudentMutate,
    isLoading: isUpdatingHomework,
  } = useMutation(
    'locked-all-homework-for-student',
    lockedAllHomeworkForStudent,
    {
      onSuccess: (e: any) => {
        if (e.status === 'success') {
          handleMessage(true, 'Process was successful', 'success');
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  return {
    lockedAllHomeworkForStudentMutate,
    isUpdatingHomework,
  };
};
