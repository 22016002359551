import { IconButton, Tooltip, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';

const InternalTransactionsActionButton = ({ transactionId }) => {
  const { t } = useTranslation();

  return (
    <Typography noWrap>
      <Tooltip title={t('tooltip.view')} arrow>
        <IconButton
          component={RouterLink}
          to={`/dashboard/accounting/internal-transactions/${transactionId}`}
          color="primary"
        >
          <LaunchTwoToneIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Typography>
  );
};

export default InternalTransactionsActionButton;
