import { useTranslation } from 'react-i18next';
import { Box, Grid, FormControlLabel, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import { OmInput } from 'src/shared/components/form-components/om-input';
import { OmSelect } from 'src/shared/components/form-components/om-select';
import { OmDateTimePicker } from 'src/shared/components/form-components/om-date-time-picker';

export const LessonForm = ({ formik, data }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} md={6}>
          <OmInput formik={formik} label={t('subject')} name="subject" />
        </Grid>
        {data?.group.lessonType !== 'online' && (
          <Grid item xs={12} md={6}>
            <OmSelect
              formik={formik}
              label={t('room')}
              name="heldIn"
              options={data?.rooms || []}
            />
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <OmDateTimePicker
            formik={formik}
            label={t('lessonStarting')}
            name="lessonStarted"
            ampm={false}
          />
          {!!formik.errors.lessonStarted && formik.touched.lessonStarted && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943',
              }}
            >
              {formik.errors.lessonStarted}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <OmDateTimePicker
            formik={formik}
            label={t('lessonEnding')}
            name="lessonEnded"
            ampm={false}
          />
          {!!formik.errors.lessonEnded && formik.touched.lessonEnded && (
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#FF1943',
              }}
            >
              {formik.errors.lessonEnded}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <OmInput
            formik={formik}
            label={t('duration')}
            name="duration"
            type="number"
            onWheel={(e: any) => e.target.blur()}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Box>
            <b>{t('pages.addLesson.lessonDetails.homeworkWillBeAddedLater')}</b>
          </Box>
          <FormControlLabel
            value="yes"
            control={
              <Radio
                checked={formik.values.homeworkAddedLater === 'yes'}
                onChange={formik.handleChange}
                value="yes"
                color="primary"
                name="homeworkAddedLater"
              />
            }
            label={t('yes')}
          />
          <FormControlLabel
            value="no"
            control={
              <Radio
                checked={formik.values.homeworkAddedLater === 'no'}
                onChange={formik.handleChange}
                value="no"
                color="primary"
                name="homeworkAddedLater"
              />
            }
            label={t('no')}
          />
        </Grid>
        <Grid item xs={12}>
          <OmInput
            formik={formik}
            label={t('description')}
            name="description"
            multiline={true}
            rows={3}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
