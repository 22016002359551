import { RouteObject } from 'react-router';

import Authenticated from 'src/components/Authenticated';

import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';

import dashboardsRoutes from './dashboards';
import blocksRoutes from './blocks';
import applicationsRoutes from './applications';
import managementRoutes from './management';
import accountRoutes from './account';
import baseRoutes from './base';

const router: RouteObject[] = [
  {
    path: '',
    children: [...accountRoutes, ...baseRoutes]
  },

  {
    path: '/dashboard',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: [...dashboardsRoutes]
  }
];

export default router;
