import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'src/shared/types';
import { GroupHomeworkActionButtons } from '../components/group-homework-action-buttons';

export const useGroupHomeworkTable = (formik, homeworkFormFormik) => {
  const { t } = useTranslation();

  const homework = formik.values.homework;

  const columns = useMemo(() => {
    const columns: Column[] = [
      {
        id: 'title',
        align: 'left',
        label: t('table.title'),
        extructor: (item) => item.title,
      },
      {
        id: 'link',
        align: 'center',
        label: t('table.link'),
        extructor: (item) => item.link,
      },
      {
        id: 'percentage',
        align: 'center',
        label: t('table.percentage'),
        extructor: (item) => item.percentage,
      },
      {
        id: 'actions',
        align: 'right',
        label: t('table.actions'),
        extructor: (item) => (
          <GroupHomeworkActionButtons
            item={item}
            formik={formik}
            homeworkFormFormik={homeworkFormFormik}
          />
        ),
      },
    ];
    return columns;
  }, [t, homework, homeworkFormFormik]);

  return [columns];
};
