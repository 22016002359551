import { useTranslation } from 'react-i18next';
import { Card, Typography } from '@mui/material';
import { useGroupHomeworkTable } from '../hooks/use-group-homework-table';
import GenericTable from 'src/components/generic-table';

const GroupHomework = ({ formik, homeworkFormFormik }) => {
  const { t } = useTranslation();

  const homework = formik.values.homework;

  const [columns] = useGroupHomeworkTable(formik, homeworkFormFormik);

  return (
    <>
      {homework === 0 ? (
        <Card
          sx={{
            minHeight: '200px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" fontWeight="normal" color="text.secondary">
            {t('message.homeworkNotFound')}
          </Typography>
        </Card>
      ) : (
        <Card>
          <GenericTable data={homework} columns={columns} isLoading={false} />
        </Card>
      )}
    </>
  );
};

export default GroupHomework;
