import { useState } from 'react';
import { InternalTransactionsTableContext } from '../contexts/internal-transactions-table-context';

function InternalTransactionsTableContextProvider({ children }) {
  const [page, setPage] = useState('0');
  const [limit, setLimit] = useState('5');

  return (
    <InternalTransactionsTableContext.Provider
      value={{ page, limit, setPage, setLimit }}
    >
      {children}
    </InternalTransactionsTableContext.Provider>
  );
}

export default InternalTransactionsTableContextProvider;
