import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { getInternalTransactions } from '../services';
import { queryError } from 'src/shared/utils/query-error';
import { InternalTransactionsTableContext } from '../contexts/internal-transactions-table-context';

export const useInternalTransactions = () => {
  const [searchParams] = useSearchParams();
  const title = searchParams.get('title');

  const { page, limit } = useContext(InternalTransactionsTableContext);

  const { data } = useQuery({
    queryKey: ['internal-transactions-get-all', page, limit, title],
    queryFn: getInternalTransactions,
    select: ({ data }) => {
      if (data?.status === 'success') {
        return data?.data;
      }
    },
    onError: queryError,
  });

  return { data };
};
