import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export const GroupStudentsActionButtons = ({ studentId, formik }) => {
  const { t } = useTranslation();

  const studentsData = formik.values.students;

  function handleDelete() {
    const updatedStudents = studentsData.filter(
      (itemObj) => itemObj.id !== studentId,
    );
    formik.setFieldValue('students', updatedStudents);
  }

  return (
    <>
      <Tooltip title={t('tooltip.delete')} arrow>
        <IconButton onClick={handleDelete} color="error">
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};
