import { InternalTransactionsContainer } from '../containers/internal-transactions';
import InternalTransactionsTableContextProvider from '../provider/internal-transactions-table-context-provider';

export function InternalTransactions() {
  return (
    <InternalTransactionsTableContextProvider>
      <InternalTransactionsContainer />;
    </InternalTransactionsTableContextProvider>
  );
}
