import { Grid, Box, Typography } from '@mui/material';
import SubmitButton from 'src/shared/components/Submit-button';
import { useTranslation } from 'react-i18next';
import { OmInput } from 'src/shared/components/form-components/om-input';

export const StudentDetailsForm = ({ formik, onKeyDown, isSubmiting }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{ p: 3 }}
      component="form"
      onKeyDown={onKeyDown}
      onSubmit={formik.handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.name')}
            name="name"
            readOnly
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.surname')}
            name="surname"
            readOnly
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.email')}
            name="email"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.gender')}
            name="gender"
            readOnly
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.number')}
            name="number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.code')}
            name="code"
            readOnly
            disabled
            startAdornment={<Typography>TA</Typography>}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OmInput
            formik={formik}
            label={t('inputs.labels.biography')}
            name="biography"
            readOnly
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton
            load={isSubmiting}
            sx={{ width: { xs: '100%', md: 200 } }}
          >
            {t('buttons.confirm')}
          </SubmitButton>
        </Grid>
      </Grid>
    </Box>
  );
};
