import { StudentsContainer } from '../containers/students';
import StudentsTableContextProvider from '../provider/students-table-context-provider';

export function Students() {
  return (
    <StudentsTableContextProvider>
      <StudentsContainer />;
    </StudentsTableContextProvider>
  );
}
