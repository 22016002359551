import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import cuid from 'cuid';

import { createLessons, getGroup } from '../services';
import { addGroupLessonMapper } from '../mappers/add-group-lesson-mapper';

import { queryError } from 'src/shared/utils/query-error';

import { AlertContext } from 'src/shared/contexts/Alert';
import getDateLesson from '../utility/get-date-lesson';

export const useAddLesson = () => {
  const { t } = useTranslation();

  const { handleMessage } = useContext(AlertContext);

  const navigate = useNavigate();

  const { groupId } = useParams();

  const { data } = useQuery(['get-group', groupId], getGroup, {
    select: ({ data }) => {
      if (data?.status === 'success') {
        return {
          group: data.group,
          students: data.group.students,
          lessons: data.lessons,
          rooms: data?.rooms.map((room) => {
            return {
              id: room,
              name: room,
            };
          }),
          timeOptions: data.timeOptions,
          groupId: data.group.id,
        };
      }
    },
    onError: queryError,
  });

  const { mutate: createNewLesson, isLoading: isCreatingLessons } = useMutation(
    'create-lessons',
    createLessons,
    {
      onSuccess: (e: any) => {
        if (e.status === 'success') {
          handleMessage(true, 'Process was successful', 'success');
          formik.resetForm();
          navigate(-1);
        } else {
          handleMessage(true, e.message, 'error');
        }
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const teacherId = data?.group?.teacherId;
  const program = data?.group?.program;

  const validationSchemaNewLessonForm = Yup.object().shape({
    subject: Yup.string().required(t('yup.inputs.subject')),
    heldIn: Yup.string().required(t('yup.inputs.room')),
    lessonStarted: Yup.string()
      .nullable()
      .required(t('yup.inputs.lessonStarting')),
    lessonEnded: Yup.string().nullable().required(t('yup.inputs.lessonEnding')),
    duration: Yup.number()
      .nullable()
      .max(200, t('yup.inputs.duration.max'))
      .required(t('yup.inputs.duration.required')),
    description: Yup.string(),
    homework: Yup.array().when('homeworkAddedLater', {
      is: (value) => value === 'no',
      then: Yup.array()
        .min(1, t('yup.inputs.homework'))
        .required(t('yup.inputs.homework')),
    }),
  });

  const validationHomeworkModalForm = Yup.object().shape({
    title: Yup.string().required(t('yup.inputs.title')),
    percentage: Yup.number()
      .nullable()
      .test(
        'general-percent',
        t('yup.inputs.percentage.total'),
        function (value) {
          const homeworkPercentageTotal = formik.values.homework.reduce(
            (acc, curVal) => acc + curVal.percentage,
            0,
          );
          if (homeworkFormFormik.values.modalType === 'edit') {
            return (
              value +
                homeworkPercentageTotal -
                homeworkFormFormik.values.editPercentage <=
              100
            );
          }
          return value + homeworkPercentageTotal <= 100;
        },
      )
      .required(t('yup.inputs.percentage.required')),
  });

  const formik = useFormik({
    initialValues: {
      subject: '',
      heldIn: data?.group.room || 0,
      lessonStarted: getDateLesson(
        data?.group.lessonsStarts,
        data?.timeOptions,
      ),
      lessonEnded: getDateLesson(data?.group.lessonsEnds, data?.timeOptions),
      duration: data?.group.lessonsEnds - data?.group.lessonsStarts || 0,
      description: '',
      homeworkAddedLater: 'no',
      students: data?.students || [],
      homework: [],
    },
    onSubmit: (values) => {
      const homeworkPercentageTotal = formik.values.homework.reduce(
        (acc, curVal) => acc + curVal.percentage,
        0,
      );
      const addGroupLessonBody = addGroupLessonMapper({
        values,
        teacherId,
        groupId,
        program,
      });

      if (
        (values.homeworkAddedLater === 'no' && homeworkPercentageTotal < 100) ||
        homeworkPercentageTotal > 100
      ) {
        handleMessage(true, t('modal.pleaseCheckYourFileAgain'), 'error');
        return;
      }

      if (
        values.homeworkAddedLater === 'yes' ||
        homeworkPercentageTotal === 100
      ) {
        createNewLesson(addGroupLessonBody);
      }
    },

    validationSchema: validationSchemaNewLessonForm,
    enableReinitialize: true,
  });

  const homeworkFormFormik = useFormik({
    initialValues: {
      id: '',
      title: '',
      percentage: '',
      editPercentage: '',
      link: '',
      homeworkFormDescription: '',
      homeworkFormIsOpen: false,
      modalType: 'add',
    },
    onSubmit: (values) => {
      if (values.title.trim() && values.percentage !== '') {
        const foundHomeworkIndex = formik.values.homework.findIndex(
          (item) => item.id === values.id,
        );

        const updatedHomework = {
          id: cuid(),
          title: values.title,
          link: values.link,
          percentage: values.percentage,
        };

        if (foundHomeworkIndex === -1) {
          formik.setFieldValue('homework', [
            ...formik.values.homework,
            updatedHomework,
          ]);
        } else {
          const updatedHomeworkList = [...formik.values.homework];

          updatedHomeworkList[foundHomeworkIndex] = {
            id: values.id,
            title: values.title,
            link: values.link,
            percentage: values.percentage,
          };

          formik.setFieldValue('homework', updatedHomeworkList);
        }

        homeworkFormFormik.setFieldValue('homeworkFormIsOpen', false);
        homeworkFormFormik.resetForm();
      }
    },

    validationSchema: validationHomeworkModalForm,
    enableReinitialize: true,
  });

  return {
    data,
    formik,
    homeworkFormFormik,
    isCreatingLessons,
  };
};
