import { Typography } from '@mui/material';

export function TransactionsTableAmountCell({
  type,
  category,
  amount,
}: {
  type: string;
  category: string;
  amount: number;
}) {
  const status = () => {
    if (category === 'deposit') {
      return {
        status: 'income',
        color: '#57CA22',
      };
    }
    if (category === 'withdraw') {
      return {
        status: 'expense',
        color: '#FF1943',
      };
    }
    if (type === 'income') {
      return {
        status: 'income',
        color: '#57CA22',
      };
    } else {
      if (category === 'rejoin') {
        return {
          status: 'income',
          color: '#57CA22',
        };
      } else {
        return {
          status: 'expense',
          color: '#FF1943',
        };
      }
    }
  };

  return (
    <Typography style={{ color: status().color, fontWeight: 'bold' }}>
      {status().status === 'income' ? ` + ${amount} ₼` : `- ${amount} ₼`}
    </Typography>
  );
}
