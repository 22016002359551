import {
  Box,
  Checkbox,
  Typography,
  TextField,
  InputAdornment
} from '@mui/material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { useTranslation } from 'react-i18next';

const TableHeader = ({
  selectedAllRow,
  selectedSomeRow,
  handleSelectAllRow,
  selectedBulkActions,
  pagelength,
  selectedItems,
  select,
  search,
  handleSearch,
  searchWith
}) => {
  const { t } = useTranslation();

  return (
    <Box px={2} display="flex" alignItems="center">
      {search === 'only-search'
        ? ''
        : select && (
            <Checkbox
              checked={selectedAllRow}
              indeterminate={selectedSomeRow}
              onChange={handleSelectAllRow}
            />
          )}
      {!selectedBulkActions ? (
        search === 'only-search' ? (
          <Box
            sx={{
              height: '76px',
              position: 'relative',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <TextField
              sx={{
                m: 0,
                width: '100%'
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
              onChange={handleSearch}
              placeholder={t(`Search by ${searchWith}...`)}
              size="small"
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Box>
        ) : (
          select && (
            <Box
              flex={1}
              p={2}
              display={{ xs: 'block', sm: 'flex' }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography component="span" variant="subtitle1">
                  {t('Showing')}:
                </Typography>
                <b>{pagelength}</b> <b>{t('items')}</b>
              </Box>
            </Box>
          )
        )
      ) : null}
    </Box>
  );
};

export default TableHeader;
