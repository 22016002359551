import Label from 'src/shared/components/Label';
import { Colors } from '../types';

const TypeStatus = ({ type, category }: { type: string; category: string }) => {
  const typeStatus = () => {
    if (type === 'income') {
      return {
        text: 'income',
        color: 'success',
      };
    } else {
      if (category === 'rejoin') {
        return {
          text: 'income',
          color: 'success',
        };
      } else {
        return {
          text: 'expense',
          color: 'error',
        };
      }
    }
  };
  return (
    <Label color={typeStatus().color as Colors}>
      <b>{typeStatus().text}</b>
    </Label>
  );
};

export default TypeStatus;
