import { AxiosResponse } from 'axios';

import axios from 'src/utils/axios';
import getAuthToken from 'src/utils/getAuthToken';

export const getTransactions = async (query): Promise<AxiosResponse> => {
  const [, page, limit, title] = query.queryKey;

  const queryObj: any = {
    ...(page && { page: Number(page) + 1 }),
    ...(limit && { limit: limit }),
    ...(title && { query: title }),
  };

  const queryString = '?' + new URLSearchParams(queryObj).toString();

  try {
    return await axios.get(`/api/v2/teacher-api/transactions${queryString}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getTransactionById = async (id: string | undefined) => {
  try {
    return await axios.get(`/api/v1/transactions/${id}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const getSales = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v2/admin-api/transactions/sales`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const updateTransaction = async (
  transaction: any,
): Promise<AxiosResponse> => {
  try {
    const { data: newData } = await axios.post(
      `/api/v1/transactions/${transaction.id}`,
      transaction.data,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
    return newData;
  } catch (e) {
    return e;
  }
};

export const addTransaction = async (data): Promise<AxiosResponse> => {
  try {
    const { data: newData } = await axios.post(`/api/v1/transactions`, data, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
    return newData;
  } catch (e) {
    return e;
  }
};
