const enJSON = {
  students: {
    title: 'students',
  },
  homework: {
    title: 'homework',
    homeworkForm: 'Homework form',
  },
  groups: {
    title: 'groups',
    groupDetails: {
      title: 'group details',
      addLesson: 'add lesson',
    },
  },
  transactions: {
    title: 'transactions',
  },
  internalTransactions: {
    title: 'internal transactions',
  },
  internalTransactionDetails: {
    title: 'internal transaction details',
  },
  completion: {
    name: 'completion',
  },
  stats: {
    title: 'stats',
    internalBalance: 'internal balance',
  },
  popover: {
    title: 'profile',
    signOut: 'sign out',
    lessonMode: 'lesson mode',
  },
  pages: {
    home: 'home',
    studentDetails: 'student details',
    lessons: 'lessons',
    addLesson: {
      title: 'add lesson',
      lessonDetails: {
        title: 'lesson details',
        homeworkWillBeAddedLater: 'Homework will be added later',
        createHomework: 'Create homework',
      },
    },
  },
  modal: {
    pleaseCheckYourFileAgain:
      'Please check your homework again, percentage sum should be equal to 100.',
    notFoundData: 'Not Found Data',
    theDataInTheSublistIsLeftBlank: 'The data in the sublist is left blank',
  },
  message: {
    studentNotFound: 'Student not found',
    homeworkNotFound: 'Homework not found',
    homeworkNotAdded: 'homework not added',
    dataNotFound: 'Data not found!',
    noHomeworkAddedDoYouWantToAdd: 'No homework added. Do you want to add?',
  },
  heading: {
    general: 'general',
    management: 'management',
  },
  accounting: {
    title: 'accounting',
    invoiceDetails: {
      title: 'invoice',
      issuedOn: 'issued on',
      issuedBy: 'issued by',
      teacherBalanceBeforePayment: 'balance before payment',
      teacherBalanceAfterPayment: 'balance after payment',
    },
  },
  profile: {
    title: 'profile',
    changeCover: 'change cover',
    tabs: {
      myAccount: 'my account',
      aboutMe: 'about me',
    },
  },
  table: {
    name: 'name',
    title: 'title',
    code: 'code',
    link: 'link',
    percentage: 'percentage',
    fullname: 'fullname',
    actions: 'actions',
    status: 'status',
    email: 'email',
    room: 'room',
    students: 'students',
    subject: 'subject',
    category: 'category',
    amount: 'amount',
    date: 'date',
    type: 'type',
  },
  tabs: {
    lessons: 'lessons',
    students: 'students',
    resources: 'resources',
    statistics: 'statistics',
  },
  tooltip: {
    view: 'view',
    lockedAllHomeworkForStudent: 'Locked all homework for student',
    lockedHomeworkForStudent: 'Locked homework for student',
    unlockHomeworkForStudent: 'Unlock homework for student',
    finishEditing: 'Finish editing',
    startEditing: 'Start editing',
    list: 'list',
    delete: 'delete',
    edit: 'edit',
  },
  inputs: {
    labels: {
      changePassword: 'change password',
      changeEmail: 'change email',
      currentPassword: 'current password',
      password: 'password',
      passwordConfirm: 'password confirm',
      email: 'email',
      name: 'name',
      surname: 'surname',
      gender: 'gender',
      university: 'university',
      interests: 'interests',
      birthOfDate: 'birth of date',
      number: 'number',
      biography: 'biography',
      code: 'code',
    },
    placeholders: {},
  },
  buttons: {
    confirm: 'confirm',
    importExcelFile: 'import excel file',
    add: 'add',
    seeMore: 'see more',
    close: 'close',
    addHomework: 'add homework',
    confirmWithoutAdd: 'confirm without add',
  },
  comments: {
    name: 'comments',
  },
  yup: {
    currentPassword: 'current password is requierd',
    password: {
      required: 'password is requierd',
      min: 'password is requierd',
    },
    passwordConfirm: {
      required: 'confirm password is required.',
      correctly: 'does not match the repeat password',
    },
    email: {
      required: 'email is required',
      correctly: 'email not entered correctly',
    },
    inputs: {
      duration: {
        required: 'duration is required',
        max: 'max 200 min.',
      },
      percentage: {
        required: 'percentage is required',
        total: 'total should be 100%',
      },
      name: 'name is required',
      surname: 'surname is required',
      gender: 'gender is required',
      university: 'university is required',
      subject: 'subject is required',
      room: 'room is required',
      lessonStarting: 'lesson starting is required',
      lessonEnding: 'lesson ending is required',
      title: 'title is required',
      link: 'link is required',
      homework: 'homework is required',
      biography: 'biography is required',
    },
  },
  subject: 'subject',
  room: 'room',
  lessonStarting: 'lesson starting',
  lessonEnding: 'lesson ending',
  duration: 'duration',
  description: 'description',
  title: 'title',
  percentage: 'percentage',
  link: 'link',
  login: {
    signIn: 'sign in',
    info: 'fill in the fields below to sign into your account.',
    lostPassword: 'lost password ?',
    signUp: 'sign up here',
  },
  recoverPassword: {
    title: 'recover password',
    info: 'enter the email used for registration to reset your password',
    sendPassword: 'send me a new password',
    signInAgain: 'want to try to sign in again?',
    clickHere: 'click here',
  },
  languageSwitcher: 'language',
  amount: 'amount',
  selectInput: {
    female: 'female',
    male: 'male',
    unkown: 'unkown',
  },
  yes: 'yes',
  no: 'no',
};

export default enJSON;
