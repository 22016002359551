import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router';

export const GroupHomeworkActionButtons = ({
  item,
  formik,
  homeworkFormFormik,
}) => {
  const { t } = useTranslation();

  const { lessonId } = useParams();

  const homework = formik.values.homework;

  function handleDelete() {
    if (item._id === homeworkFormFormik.values.id) {
      homeworkFormFormik.setFieldValue('title', '');
      homeworkFormFormik.setFieldValue('homeworkFormIsOpen', false);
      homeworkFormFormik.setFieldValue('percentage', 0);
      homeworkFormFormik.setFieldValue('calculatePercentage', 0);
      homeworkFormFormik.setFieldValue('editPercentage', '');
      homeworkFormFormik.setFieldValue('link', '');
      homeworkFormFormik.setFieldValue('homeworkFormDescription', '');
    }

    const updatedHomework = homework.filter((itemObj) =>
      lessonId ? itemObj._id !== item._id : itemObj.id !== item.id,
    );

    formik.setFieldValue('homework', updatedHomework);
  }

  function handleEdit() {
    homeworkFormFormik.setFieldValue('homeworkFormIsOpen', true);
    homeworkFormFormik.setFieldValue('modalType', 'edit');

    const updatedHomework = homework.find((itemObj) =>
      lessonId ? itemObj._id === item._id : itemObj.id === item.id,
    );

    homeworkFormFormik.setFieldValue(
      'id',
      lessonId ? updatedHomework._id : updatedHomework.id,
    );
    homeworkFormFormik.setFieldValue('title', updatedHomework.title);
    homeworkFormFormik.setFieldValue('percentage', updatedHomework.percentage);
    homeworkFormFormik.setFieldValue(
      'calculatePercentage',
      updatedHomework.percentage,
    );
    homeworkFormFormik.setFieldValue(
      'editPercentage',
      updatedHomework.percentage,
    );
    homeworkFormFormik.setFieldValue('link', updatedHomework.link);
    homeworkFormFormik.setFieldValue(
      'homeworkFormDescription',
      updatedHomework.description,
    );
  }

  
  return (
    <>
      <Tooltip title={t('tooltip.delete')} arrow>
        <IconButton onClick={handleDelete} color="error">
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('tooltip.edit')} arrow>
        <IconButton color="primary" onClick={handleEdit}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};
