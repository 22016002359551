import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button, Modal } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Visibility from '@mui/icons-material/Visibility';
import Text from 'src/shared/components/Text';
import {
  ILessonHomeworkResultsLessonHomework,
  ILessonHomeworkResultsTasks,
} from '../types';

export default function QuestionList({
  listHomeworkTasks,
  lessonHomework,
}: {
  listHomeworkTasks: ILessonHomeworkResultsTasks[];
  lessonHomework: ILessonHomeworkResultsLessonHomework[];
}) {
  const { t } = useTranslation();

  const [isModal, setModal] = useState(false);

  const handleModal = () => {
    setModal(!isModal);
  };

  const countOfquestions = listHomeworkTasks
    ?.sort((a, b) => {
      return b.questions.length - a.questions.length;
    })
    .map((task) => task.questions.length);

  const countOfQuestions = listHomeworkTasks?.reduce(
    (acc, listHomeworkTask) => {
      acc[listHomeworkTask.task] = listHomeworkTask.questions.length;
      return acc;
    },
    {},
  );

  const calcCountOfAllQuestion = countOfquestions?.reduce(
    (partial_sum, a) => partial_sum + a,
    0,
  );

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ py: 1, fontSize: 16 }} variant="body1">
          {t('comments.name')}{' '}
          <Text color={!calcCountOfAllQuestion ? 'primary' : 'success'}>
            {!calcCountOfAllQuestion ? '(0)' : `(${calcCountOfAllQuestion})`}
          </Text>
        </Typography>
        <Button
          variant="outlined"
          startIcon={<Visibility />}
          size="small"
          onClick={handleModal}
          disabled={calcCountOfAllQuestion === undefined}
          sx={{
            fontSize: { xs: '11px', sm: '13px', md: '13px' },
            px: { xs: '10px', sm: '12px', md: '12px' },
            py: { xs: '3px', sm: '6px', md: '6px' },
          }}
        >
          {t('buttons.seeMore')}
        </Button>
      </Box>
      <Modal
        open={isModal}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ outline: 'none' }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { md: '500px', xs: '95%' },
            bgcolor: 'background.paper',
            boxShadow: 12,
            borderRadius: '10px',
            p: 2,
            '&:focus': {
              outline: 'none',
            },
            '&:focus-within': {
              outline: 'none',
            },
          }}
        >
          {lessonHomework
            ?.sort((a, b) => {
              if (!countOfQuestions) return -1;

              return countOfQuestions[b._id] - countOfQuestions[a._id];
            })
            .map((item, index) => {
              const foundQuestion = listHomeworkTasks?.find(
                (listHomeworkTask) => {
                  return listHomeworkTask.task === item._id;
                },
              );

              return (
                <Box
                  key={index}
                  sx={{
                    border: '1px solid',
                    p: 1,
                    borderRadius: '10px',
                    mb: 1,
                  }}
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        p: 0,
                        minHeight: `21px!important`,
                        height: 21,
                        '& .MuiAccordionSummary-content': {
                          mx: 0,
                        },
                      }}
                    >
                      <Typography>
                        {item?.title}{' '}
                        <Text
                          color={
                            foundQuestion?.questions?.length === 0
                              ? 'primary'
                              : 'success'
                          }
                        >
                          ({foundQuestion?.questions?.length})
                        </Text>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {foundQuestion?.questions ? (
                        <ol style={{ paddingLeft: '24px' }}>
                          {foundQuestion.questions.map((question, index) => {
                            return <li key={index}>{question.title}</li>;
                          })}
                        </ol>
                      ) : (
                        <Typography
                          sx={{ margin: '5px 0', textAlign: 'center' }}
                          variant="subtitle1"
                        >
                          {t('modal.notFoundData')}
                        </Typography>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })}
        </Box>
      </Modal>
    </Box>
  );
}
