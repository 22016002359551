import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { useStudents } from '../hooks/use-students';
import { useStudentsTable } from '../hooks/use-students-table';
import { useContext, useState } from 'react';
import { StudentsTableContext } from '../contexts/students-table-context';
import GenericTableV2 from 'src/shared/components/generic-table-v2';
import { Box, Card, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StudentsSearch from '../components/students-search';

export function StudentsContainer() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const { page, limit, setPage, setLimit } = useContext(StudentsTableContext);

  const [columns, options, routes] = useStudentsTable('students-table');

  const { data } = useStudents();

  const handleFilter = () => {
    setIsOpen(!isOpen);
  };
  return (
    <PageWrapper isPaper={false} routes={routes} title={t('students.title')}>
      <Card
        sx={{
          p: 2,
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '4px',
        }}
      >
        <Box
          onClick={handleFilter}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%', cursor: 'pointer' }}
        >
          <Typography>Filter</Typography>
          {!isOpen && <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} />}
          {isOpen && <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} />}
        </Box>
        {isOpen && <StudentsSearch />}
      </Card>
      <Card>
        <GenericTableV2
          rows={data}
          columns={columns}
          options={options}
          limit={limit}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
        />
      </Card>
    </PageWrapper>
  );
}
