import { InternalTransactionDetails } from '../modules/internal-transactions/pages/internal-transaction-details';
import { InternalTransactions } from '../modules/internal-transactions/pages/internal-transactions';
import { Stats } from '../modules/stats/pages/stats';
import { Transactions } from '../modules/transactions/pages/transactions';

const accountingRouter = {
  path: 'accounting',
  children: [
    {
      path: 'transactions',
      element: <Transactions />,
    },
    {
      path: 'internal-transactions',
      element: <InternalTransactions />,
    },
    {
      path: 'internal-transactions/:internalTransactionId',
      element: <InternalTransactionDetails />,
    },
    {
      path: 'stats',
      element: <Stats />,
    },
  ],
};

export default accountingRouter;
