import PageWrapper from 'src/shared/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { useInternalTransactions } from '../hooks/use-internal-transactions';
import { useContext, useState } from 'react';
import { InternalTransactionsTableContext } from '../contexts/internal-transactions-table-context';
import { Box, Card, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InternalTransactionSearch from '../components/internal-transaction-search';
import GenericTableV2 from 'src/shared/components/generic-table-v2';
import { useInternalTransactionsTable } from '../hooks/use-internal-transactions-table';

export function InternalTransactionsContainer() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const [columns, options, routes] = useInternalTransactionsTable();

  const { data } = useInternalTransactions();

  const { page, limit, setPage, setLimit } = useContext(
    InternalTransactionsTableContext,
  );

  const handleFilter = () => {
    setIsOpen(!isOpen);
  };

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('internalTransactions.title')}
    >
      <Card
        sx={{
          p: 2,
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '4px',
        }}
      >
        <Box
          onClick={handleFilter}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%', cursor: 'pointer' }}
        >
          <Typography>Filter</Typography>
          {!isOpen && <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} />}
          {isOpen && <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} />}
        </Box>
        {isOpen && <InternalTransactionSearch />}
      </Card>
      <Card>
        <GenericTableV2
          rows={data}
          columns={columns}
          options={options}
          limit={limit}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
        />
      </Card>
    </PageWrapper>
  );
}
