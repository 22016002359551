import { useQuery } from 'react-query';
import { getTransactions } from '../services';
import { queryError } from 'src/shared/utils/query-error';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { TransactionsTableContext } from '../contexts/transactions-table-context';

export const useTransactions = () => {
  const [searchParams] = useSearchParams();
  const title = searchParams.get('title');

  const { page, limit } = useContext(TransactionsTableContext);

  const { data } = useQuery({
    queryKey: ['transactions-get-all', page, limit, title],
    queryFn: getTransactions,
    select: ({ data }) => {
      if (data?.status === 'success') {
        return data?.transactions;
      }
    },
    onError: queryError,
  });

  return { data };
};
