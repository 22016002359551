import { Link } from 'react-router-dom';

import {
  Grid,
  Box,
  Avatar,
  Card,
  Typography,
  Divider,
  useTheme,
  ListItem,
  LinearProgress,
  styled,
} from '@mui/material';

const ListItemWrapper = styled(ListItem)(
  () => `
            border-radius: 0;
    `,
);

const LinearProgressWrapper = styled(LinearProgress)(
  ({ theme }) => `
            flex-grow: 1;
            margin-right: ${theme.spacing(1)};
    `,
);

const GetProgressColor = (
  percent: number,
): 'error' | 'warning' | 'info' | 'success' => {
  if (50 >= percent) {
    return 'error';
  }
  if (50 < percent && 60 > percent) {
    return 'warning';
  }
  if (60 < percent && 80 > percent) {
    return 'info';
  } else {
    return 'success';
  }
};
const StatisticsItem = ({ item }) => {
  const theme = useTheme();

  return (
    <Grid item xs={12} md={4}>
      <Card
        sx={{
          p: 3,
          textAlign: 'center',
          height: '350px',
        }}
      >
        <Avatar
          sx={{
            width: 90,
            height: 90,
            mb: 2,
            mx: 'auto',
            border: `${theme.colors.alpha.white[100]} solid 3px`,
            boxShadow: `0 0 0 3px ${theme.colors.primary.main}`,
          }}
          src={item.profileImage}
        />

        {/* <Link to={'/dashboard/learning'} style={{ textDecoration: 'none' }}>
          <Typography gutterBottom variant="h3">
            {item.fullname}
          </Typography>
        </Link> */}
        <Typography gutterBottom variant="h3">
          {item.fullname}
        </Typography>

        <Typography variant="subtitle2" sx={{ height: '30px' }}>
          {item.university}
        </Typography>
        <Divider
          sx={{
            my: 2,
          }}
        />
        <Box display="flex" justifyContent="center">
          <ListItemWrapper
            sx={{
              py: 3.15,
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              sx={{
                ml: 1,
                flexGrow: 1,
              }}
            >
              <LinearProgressWrapper
                value={parseInt(item.averagePercentage)}
                color={GetProgressColor(parseInt(item.averagePercentage))}
                variant="determinate"
              />
              <Typography variant="h4" color="text.primary">
                {parseInt(item.averagePercentage)}%
              </Typography>
            </Box>
          </ListItemWrapper>
        </Box>
      </Card>
    </Grid>
  );
};

export default StatisticsItem;
