import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import React from 'react';

type OmSelectProps = {
  formik: any;
  label: string;
  name: string;
  required?: boolean;
  options: any[];
  readOnly?: boolean;
};

export const OmSelect: React.FC<OmSelectProps> = ({
  formik,
  label,
  name,
  required,
  options,
  readOnly,
}) => {
  return (
    <FormControl
      required={required}
      variant="outlined"
      fullWidth
      error={formik.touched[name] && Boolean(formik.errors[name])}
    >
      <InputLabel htmlFor="title">{label}</InputLabel>
      <Select
        id={name}
        label={label}
        readOnly={readOnly}
        {...formik.getFieldProps(name)}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {formik.touched[name] && Boolean(formik.errors[name]) && (
        <FormHelperText sx={{ ml: 0 }}>{formik.errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
};
