import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryError } from 'src/shared/utils/query-error';
import { StatisticsContext } from '../contexts/statistics-context';
import { getGroupAverageStatistics } from '../services';

function StatisticsProvider({ children }) {
  const [studentAverages, setStudentAverages] = useState([]);
  const { groupId } = useParams();

  const { data, refetch, isLoading } = useQuery(
    'get-statistics',
    () => {
      return getGroupAverageStatistics(groupId);
    },
    {
      select: ({ data }) => {
        if (data?.status === 'success') {
          // const statistics: IStatisticsContext[] = studentGetAllDataMapper(data);
          return data;
        }
      },
      onError: queryError
    }
  );

  useEffect(() => {
    if (data) {
      setStudentAverages(data.studentAverages);
    }
  }, [data]);

  const value = {
    statistics: {
      studentAverages
    },
    refetch,
    isLoading
  };

  return (
    <StatisticsContext.Provider value={value}>
      {children}
    </StatisticsContext.Provider>
  );
}

export default StatisticsProvider;
