import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useLessonsTable } from 'src/modules/lessons/hooks/use-lessons-table';
import AddLessonForm from '../components/add-lesson-form';

export const AddLessonContainer = () => {
  const [, , routes] = useLessonsTable();

  const { t } = useTranslation();

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('pages.addLesson.title')}
    >
      <AddLessonForm />
    </PageWrapper>
  );
};
