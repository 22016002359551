import { IGroup, IGroupAPI } from '../types';

function groupMapper(group: IGroupAPI): IGroup {
  return {
    id: group.id,
    code: group.code,
    status: group.status,
    room: group.room,
    students: group.students
  };
}

export function groupGetAllDataMapper(data: any): IGroup[] {
  const groups = data.groups.map((group) => {
    return groupMapper(group);
  });
  return groups;
}
