import {
  Typography,
  Box,
  Avatar,
  Card,
  Grid,
  useTheme,
  styled,
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import fixDecimal from 'src/utils/fix-decimal';

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
          color:  ${theme.colors.alpha.trueWhite[100]};
          width: ${theme.spacing(5.5)};
          height: ${theme.spacing(5.5)};
    `,
);

export function StatsCard({
  data,
  title,
  icon: Icon,
}: {
  data: any;
  title: string;
  icon: any;
}) {
  const theme = useTheme();
  const [showData, setShowData] = useState<boolean>(true);

  const handlePersonal = () => {
    setShowData(!showData);
  };

  return (
    <Grid item xs={12} sm={6} lg={3}>
      <Card
        sx={{
          px: 3,
          pb: 6,
          pt: 3,
        }}
      >
        <Box display="flex" alignItems="center">
          <AvatarWrapper
            sx={{
              background: `${theme.colors.gradients.blue4}`,
            }}
          >
            <Icon fontSize="small" />
          </AvatarWrapper>
          <Typography
            sx={{
              ml: 1.5,
              fontSize: `${theme.typography.pxToRem(15)}`,
              fontWeight: 'bold',
            }}
            variant="subtitle2"
            component="div"
          >
            {title}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            ml: -2,
            pt: 2,
            pb: 1.5,
            justifyContent: 'center',
          }}
          gap={1}
        >
          <Typography
            sx={{
              pl: 1,
              fontSize: `${theme.typography.pxToRem(35)}`,
            }}
            variant="h1"
          >
            {showData ? '******' : `${fixDecimal(data)}`}
          </Typography>
          {
            <IconButton onClick={handlePersonal}>
              {showData ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          }
        </Box>
      </Card>
    </Grid>
  );
}
