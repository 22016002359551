import { AxiosResponse } from "axios";
import axios from "src/utils/axios";
import getAuthToken from "src/utils/getAuthToken";

export const getStats = async (): Promise<AxiosResponse> => {
    try {
      return await axios.get(`/api/v2/teacher-api/transactions/stats`, {
        headers: {
          Authorization: 'Bearer ' + getAuthToken()
        }
      });
    } catch (e) {
      return e;
    }
  };
  