import StudentDetails from '../pages/student-details';
import { Students } from '../pages/students';

const studentsRouter = {
  path: 'students',
  children: [
    {
      path: '',
      element: <Students />,
    },
    {
      path: ':studentId',
      element: <StudentDetails />,
    },
  ],
};

export default studentsRouter;
