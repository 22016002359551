import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { queryError } from 'src/shared/utils/query-error';
import { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { AlertContext } from 'src/shared/contexts/Alert';
import { useTranslation } from 'react-i18next';
import { getStudentbyId, updateStudent } from '../services';

export function useStudentEditForm(studentId?: string) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { handleMessage } = useContext(AlertContext);

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['student-details', studentId],
    () => {
      if (studentId) {
        return getStudentbyId(studentId);
      }
    },
    {
      select: ({ data }) => {
        return data.student;
      },
      onError: queryError,
    },
  );

  const { mutate: updateStudentSubmit, isLoading: isSubmiting } = useMutation(
    'update-student',
    updateStudent,
    {
      onSuccess: () => {
        handleMessage(true, 'Process was successful', 'success');
        navigate(-1);
        refetch();
      },
      onError: (error: any) => {
        const errorMsg = error?.response?.data?.message || 'Unknown error';
        handleMessage(true, errorMsg, 'error');
      },
    },
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('name is a required field'),
  });

  const routes = useMemo(() => {
    return [
      {
        label: t('pages.home'),
        href: '/dashboard',
      },
      {
        label: t('students.title'),
        href: '/dashboard/students',
      },
      {
        label: data?.fullname ? data.fullname : t('pages.studentDetails'),
        href: '/dashboard/students/:id',
      },
    ];
  }, [data]);

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      surname: data?.surname || '',
      email: data?.email || '',
      gender: data?.gender || '',
      number: data?.number || '',
      code: data?.code || '',
      biography: data?.biography || '',
    },
    onSubmit: (values) => {
      updateStudentSubmit({ studentId, ...values });
    },
    validationSchema,
    enableReinitialize: true,
  });

  const onKeyDown = useCallback(function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }, []);

  return {
    data,
    formik,
    routes,
    isLoading,
    isSubmiting,
    onKeyDown,
  };
}
