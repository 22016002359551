import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Card, Typography } from '@mui/material';
import LoadingCircle from 'src/components/Loading-circle';
import { StatisticsContext } from '../contexts/statistics-context';

import StatisticsItem from './statistics-item';

const Statistics = () => {
  const { t } = useTranslation();

  const {
    statistics: { studentAverages },
    isLoading,
  } = useContext(StatisticsContext);

  return (
    <>
      {studentAverages.length === 0 ? (
        <Card sx={{ mt: 2 }}>
          <Typography
            sx={{
              py: 10,
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            {t('Data not found!')}
          </Typography>
        </Card>
      ) : (
        <Grid container spacing={4} sx={{ pt: 2 }}>
          {isLoading ? (
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  p: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  maxWidth: '530px',
                  height: '350px',
                }}
              >
                <LoadingCircle />
              </Card>
            </Grid>
          ) : (
            studentAverages
              .sort((a, b) => b.averagePercentage - a.averagePercentage)
              .map((item) => <StatisticsItem key={item.id} item={item} />)
          )}
        </Grid>
      )}
    </>
  );
};
export default Statistics;
