import { useQuery } from 'react-query';
import { getStudents } from '../services';
import { queryError } from 'src/shared/utils/query-error';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { StudentsTableContext } from '../contexts/students-table-context';

export const useStudents = () => {
  const [searchParams] = useSearchParams();
  const title = searchParams.get('title');

  const { page, limit } = useContext(StudentsTableContext);

  const { data } = useQuery({
    queryKey: ['students-get-all', page, limit, title],
    queryFn: getStudents,
    select: ({ data }) => {
      if (data?.status === 'success') {
        return data?.students;
      }
    },
    onError: queryError,
  });

  return { data };
};
