import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { getStats } from '../services';
import { StatsCard } from '../components/stats-card';
import PageTitleWrapper from 'src/shared/components/PageTitleWrapper';
import LoadingCircle from 'src/shared/components/Loading-circle';
import PageHeader from 'src/components/PageHeaderDocs';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Grid, Box, Card, CircularProgress } from '@mui/material';
import { useStats } from '../hooks/use-stats';

export const StatsContainer = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useStats();

  return (
    <>
      <Helmet>
        <title>{t('stats.title')}</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader title={t('stats.title')} icon={AssessmentIcon} />
      </PageTitleWrapper>
      {isLoading ? (
        <Card
          sx={{
            height: '500px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '35px',
          }}
        >
          <CircularProgress size={40} />
        </Card>
      ) : (
        <Grid
          sx={{
            px: { xs: 1, sm: 4 },
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <StatsCard
                data={data?.internalBalance}
                title={t('stats.internalBalance')}
                icon={AccountBalanceWalletIcon}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
