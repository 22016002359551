import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@mui/material';

function InternalTransactionSearch() {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      title: '',
    },
    onSubmit: () => {},
  });

  let title = formik.values.title;

  const triggerSearch = useCallback(function (title) {
    if (title.trim() !== '') {
      searchParams.set('title', title);
    } else {
      searchParams.delete('title');
    }

    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      triggerSearch(title);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [title]);

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} md={6}>
        <TextField
          label={t('inputs.labels.name')}
          fullWidth
          variant="outlined"
          autoComplete="off"
          {...formik.getFieldProps('title')}
        />
      </Grid>
    </Grid>
  );
}

export default InternalTransactionSearch;
