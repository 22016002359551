import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  Alert,
  Dialog,
  Collapse,
  Button,
  Avatar,
  styled,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const DialogWrapper = styled(Dialog)(
  () => `
          .MuiDialog-paper {
            overflow: visible;
          }
    `,
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
          background-color: ${theme.colors.error.main};
          color: ${theme.palette.error.contrastText};
          width: ${theme.spacing(12)};
          height: ${theme.spacing(12)};
          box-shadow: ${theme.colors.shadows.error};
          top: -${theme.spacing(6)};
          position: absolute;
          left: 50%;
          margin-left: -${theme.spacing(6)};
    
          .MuiSvgIcon-root {
            font-size: ${theme.typography.pxToRem(45)};
          }
    `,
);
const AvatarWarning = styled(Avatar)(
  ({ theme }) => `
          background-color: ${theme.colors.warning.main};
          color: ${theme.palette.warning.contrastText};
          width: ${theme.spacing(12)};
          height: ${theme.spacing(12)};
          box-shadow: ${theme.colors.shadows.warning};
          top: -${theme.spacing(6)};
          position: absolute;
          left: 50%;
          margin-left: -${theme.spacing(6)};
    
          .MuiSvgIcon-root {
            font-size: ${theme.typography.pxToRem(45)};
          }
    `,
);

const GroupDialog = ({ formik, open, closeDialog, type, confirm }) => {
  const { t } = useTranslation();

  return (
    <DialogWrapper
      open={open}
      maxWidth="sm"
      fullWidth
      keepMounted
      onClose={closeDialog}
    >
      <Box
        sx={{
          px: 4,
          pb: 4,
          pt: 10,
        }}
      >
        {type === 'error' && (
          <>
            <AvatarError>
              <ErrorOutlineIcon />
            </AvatarError>
            <Box textAlign="center">
              <Typography variant="h3">
                {t('modal.theDataInTheSublistIsLeftBlank')}
              </Typography>
            </Box>
            <Collapse in={true} sx={{ my: 3 }}>
              {Object.keys(formik.errors).map((errorMsg, i) => (
                <Alert severity="error" sx={{ mb: 1 }} key={i}>
                  {formik.errors[errorMsg]}
                </Alert>
              ))}
              {formik.values.homework.length === 0 &&
                (formik.errors.title || formik.errors.percentage) && (
                  <Alert severity="error" sx={{ mb: 1 }}>
                    {t('message.homeworkNotAdded')}
                  </Alert>
                )}
            </Collapse>
            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={closeDialog}
            >
              {t('buttons.close')}
            </Button>
          </>
        )}
        {type === 'warning' && (
          <>
            <AvatarWarning>
              <ErrorOutlineIcon />
            </AvatarWarning>
            <Box textAlign="center" sx={{ mb: 2 }}>
              <Typography variant="h3">
                {t('message.noHomeworkAddedDoYouWantToAdd')}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-around"
              sx={{ flexDirection: { xs: 'column', md: 'row' } }}
            >
              <Button
                fullWidth
                variant="contained"
                onClick={closeDialog}
                sx={{
                  width: { xs: '100%', md: '200px' },
                  marginBottom: { xs: '10px', md: 0 },
                  height: 'auto',
                }}
                size="large"
              >
                {t('buttons.addHomework')}
              </Button>
              <Button
                fullWidth
                variant="contained"
                size="large"
                sx={{
                  width: { xs: '100%', md: '200px' },
                  marginBottom: { xs: '10px', md: 0 },
                }}
                onClick={() => {
                  confirm();
                }}
              >
                {t('buttons.confirmWithoutAdd')}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </DialogWrapper>
  );
};

export default GroupDialog;
