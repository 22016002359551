import { AxiosResponse } from 'axios';
import axios from 'src/utils/axios';
import getAuthToken from 'src/utils/getAuthToken';

export const getInternalTransactions = async (
  query,
): Promise<AxiosResponse> => {
  const [, page, limit, title] = query.queryKey;

  const queryObj: any = {
    ...(page && { page: Number(page) + 1 }),
    ...(limit && { limit: limit }),
    ...(title && { query: title }),
  };

  const queryString = '?' + new URLSearchParams(queryObj).toString();

  try {
    return await axios.get(
      `api/v2/teacher-api/transactions/internal${queryString}`,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};

export const getInternalTransactionbyId = async (
  internalId?: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.get(
      `/api/v2/teacher-api/transactions/internal/${internalId}`,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};
