import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'src/shared/types';
import { GroupStudentsActionButtons } from '../components/group-students-action-buttons';

export const useGroupStudentTable = (formik) => {
  const { t } = useTranslation();

  const studentsData = formik.values.students;

  const columns = useMemo(() => {
    const columns: Column[] = [
      {
        id: 'fullname',
        align: 'left',
        label: t('table.fullname'),
        extructor: (item) => item.fullname,
      },
      {
        id: 'action',
        align: 'right',
        label: t('table.actions'),
        extructor: (item) => (
          <GroupStudentsActionButtons studentId={item.id} formik={formik} />
        ),
      },
    ];
    return columns;
  }, [t, studentsData]);

  return [columns];
};
