import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'src/shared/components/page-wrapper';
import { useTransactionsTable } from '../hooks/use-transactions-table';
import GenericTableV2 from 'src/shared/components/generic-table-v2';
import { TransactionsTableContext } from '../contexts/transactions-table-context';
import { useTransactions } from '../hooks/use-transactions';
import { Box, Card, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TransactionSearch from '../components/transaction-search';

export function TransactionsContainer() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const [columns, options, routes] = useTransactionsTable();

  const { page, limit, setPage, setLimit } = useContext(
    TransactionsTableContext,
  );

  const { data } = useTransactions();

  const handleFilter = () => {
    setIsOpen(!isOpen);
  };

  return (
    <PageWrapper
      isPaper={false}
      routes={routes}
      title={t('transactions.title')}
    >
      <Card
        sx={{
          p: 2,
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '4px',
        }}
      >
        <Box
          onClick={handleFilter}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%', cursor: 'pointer' }}
        >
          <Typography>Filter</Typography>
          {!isOpen && <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} />}
          {isOpen && <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} />}
        </Box>
        {isOpen && <TransactionSearch />}
      </Card>
      <Card>
        <GenericTableV2
          rows={data}
          columns={columns}
          options={options}
          limit={limit}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
        />
      </Card>
    </PageWrapper>
  );
}
