import Label from 'src/shared/components/Label';
import { Colors } from '../types';

const InternalTransactionCategoryStatus = ({
  category,
}: {
  category: string;
}) => {
  const categoryStatus = () => {
    if (category === 'deposit') {
      return {
        text: 'deposit',
        color: 'success',
      };
    } else {
      if (category === 'lesson') {
        return {
          text: 'lesson',
          color: 'success',
        };
      } else {
        return {
          text: 'withdraw',
          color: 'error',
        };
      }
    }
  };
  return (
    <Label color={categoryStatus().color as Colors}>
      <b>{categoryStatus().text}</b>
    </Label>
  );
};

export default InternalTransactionCategoryStatus;
