import { AxiosResponse } from 'axios';

import getAuthToken from 'src/utils/getAuthToken';
import axios from 'src/utils/axios';
import { IFinishTaskBody, ILockHomeworkBody } from '../types';

export const getGroups = async (): Promise<AxiosResponse> => {
  try {
    return await axios.get(`/api/v2/teacher-api/groups`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};
export const getGroup = async (reactQueryParams): Promise<AxiosResponse> => {
  const [, groupId] = reactQueryParams.queryKey;

  try {
    return await axios.get(
      `/api/v2/teacher-api/groups/${groupId}?hasRooms=true&hasTimeOptions=true&hasLessons=true`,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};
export const getGroupAverageStatistics = async (
  groupId: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.get(
      `/api/v2/teacher-api/homework/get-average-homework/${groupId}`,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
  } catch (e) {
    return e;
  }
};

export const getLessonsHomeworkResults = async (
  reactQueryParams,
): Promise<AxiosResponse> => {
  const [, lessonId] = reactQueryParams.queryKey;
  try {
    return await axios.get(`/api/v2/teacher-api/homework/${lessonId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const updateFinishTask = async (
  data: IFinishTaskBody,
): Promise<AxiosResponse> => {
  try {
    const { data: newData } = await axios.post(
      `/api/v3/teachers-api/homework/finish-task`,
      data,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
    return newData;
  } catch (e) {
    return e;
  }
};

export const lockedAllHomeworkForStudent = async (
  data,
): Promise<AxiosResponse> => {
  try {
    const { data: newData } = await axios.post(
      `/api/v3/teachers-api/homework/finish-task`,
      data,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
    return newData;
  } catch (e) {
    return e;
  }
};

export const updateLockHomework = async (
  data: ILockHomeworkBody,
): Promise<AxiosResponse> => {
  try {
    const { data: newData } = await axios.post(
      `/api/v3/teachers-api/homework/lock`,
      data,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
    return newData;
  } catch (e) {
    return e;
  }
};

export const getLesson = async (reactQueryParams): Promise<AxiosResponse> => {
  const [, lessonId] = reactQueryParams.queryKey;

  try {
    return await axios.get(`/api/v2/teacher-api/lessons/${lessonId}`, {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(),
      },
    });
  } catch (e) {
    return e;
  }
};

export const createLessons = async (data): Promise<AxiosResponse> => {
  try {
    const { data: newData } = await axios.post(
      `/api/v2/teacher-api/lessons`,
      data,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
    return newData;
  } catch (e) {
    return e;
  }
};

export const updateHomework = async (data): Promise<AxiosResponse> => {
  try {
    const { data: newData } = await axios.patch(
      `/api/v2/teacher-api/lessons/update-homework`,
      data,
      {
        headers: {
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    );
    return newData;
  } catch (e) {
    return e;
  }
};
