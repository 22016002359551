import type { ReactNode } from 'react';

// import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
// import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
// import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
// import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
// import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
// import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'heading.general',
    items: [
      {
        name: 'students.title',
        icon: GroupIcon,
        link: '/dashboard/students'
      },
      {
        name: 'groups.title',
        icon: GroupsIcon,
        link: '/dashboard/groups'
      },
    ]
  },
  {
    heading: 'heading.management',
    items: [
      {
        name: 'accounting.title',
        icon: ReceiptTwoToneIcon,
        link: '/dashboard/accounting',
        items: [
          {
            name: 'transactions.title',
            link: '/dashboard/accounting/transactions'
          },
          {
            name: 'internalTransactions.title',
            link: '/dashboard/accounting/internal-transactions'
          },
          {
            name: 'stats.title',
            link: '/dashboard/accounting/stats'
          }
        ]
      }
    ]
  }
];

export default menuItems;
